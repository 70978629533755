import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { clearAllStep } from "../../../store/appSlice";
import { useDispatch } from "react-redux";

const ModalPrivate1 = ({ open }) => {
  const dispatch = useDispatch();
  return (
    <Modal open={open}>
      <div className={clsx(style.modal__form, style["modal__form--big"])}>
        <h2 className={clsx(style.modal__title)}>
          Номер сертификата скопирован
        </h2>

        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          onClick={() => dispatch(clearAllStep())}
        >
          Закрыть
        </button>
      </div>
    </Modal>
  );
};
export default ModalPrivate1;
