import React from "react";
import ModalQuiz from "../template/ModalQuiz";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";

// import gif_1 from "../../../images/quiz/D_1.gif";
// import gif_2 from "../../../images/quiz/D_2.gif";
import gif_3 from "../../../images/quiz/D_3.gif";

const ModalQuizDance3 = ({ open }) => {
  return (
    <ModalQuiz open={open}>
      <div className={clsx(style.quiz)}>
        <div className={clsx(style.quiz__inner)}>
          <div className={clsx(style.quiz__title, styleTitle.title)}>
            <h2
              className={clsx(
                styleTitle.title__inner,
                styleTitle["title__inner--quiz"]
              )}
            >
              приз
            </h2>
          </div>

          <p className={clsx(style.quiz__text)}>
            Зажигательный танец мистера Ореха!
          </p>

          <div className={clsx(style.quiz__copy, style.copy)}>
            {/* <button
              type="button"
              className={clsx(styleBtn.button, style.copy__link)}
            >
              смотреть ещё раз
            </button> */}
          </div>
        </div>

        <figure
          className={clsx(style.quiz__maskot, style["quiz__maskot--dance3"])}
        >
          <img src={gif_3} alt="dance..." width={300} height={300} />
        </figure>
      </div>
    </ModalQuiz>
  );
};
export default ModalQuizDance3;
