import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { onPhoneInput, onPhoneKeyDown } from "../../../service/form/mask/phone";
import { useFormik } from "formik";
import * as yup from "yup";
import { required, valid } from "../../UI/form/errText";
import { useDispatch } from "react-redux";
import { recPasswordRecovery } from "../../../store/axs/recovery";
import { stepTo } from "../../../store/appSlice";
import { setPhoneNumber } from "../../../store/appSlice";

const ModalRecovery1 = ({ open }) => {
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    phone: yup.string().required(required).min(18, valid),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },

    validationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-useless-escape
      const formatPhone = values.phone.replace(/[\-\(\)\ ]/g, "");

      recPasswordRecovery(formatPhone)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }
          dispatch(setPhoneNumber(values.phone));
          dispatch(stepTo({ type: "recovery", step: 2 }));
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  });

  return (
    <Modal open={open}>
      <form onSubmit={formik.handleSubmit} className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Восстановление пароля</h2>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Телефон</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.phone && "input-block__input--error"
                )}
                placeholder="+7 (___) __ ______"
                name="phone"
                onInput={onPhoneInput}
                onKeyDown={onPhoneKeyDown}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.phone ? (
              <p className="input-block__error">{formik.errors.phone}</p>
            ) : null}
          </div>
        </div>

        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          disabled={!formik.isValid || !formik.dirty}
        >
          Восстановить
        </button>
      </form>
    </Modal>
  );
};
export default ModalRecovery1;
