import React from "react";
import style from "./Modal.module.scss";
import clsx from "clsx";
import ModalPortalErr from "../ModalPortal";
import { ReactComponent as Icon } from "../../../images/svg/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearAllStep } from "../../../store/appSlice";
import { errorClose } from "../../../store/appSlice";

const ModalQuiz = ({ children, open = false }) => {
  const dispatch = useDispatch();

  const {
    error: { isError },
  } = useSelector((state) => state.app);

  return (
    <ModalPortalErr open={open}>
      <div
        className={clsx(
          style.modal,
          style["modal--quiz "],
          !isError && "modal-js",
          isError && "modal-js-err"
        )}
      >
        <div className={clsx(style.modal__inner, style["modal__inner--quiz"])}>
          <button
            className={clsx(style.modal__close, style["modal__close--quiz"])}
            onClick={() =>
              !isError ? dispatch(clearAllStep()) : dispatch(errorClose())
            }
          >
            <Icon />
          </button>

          {children}
        </div>
      </div>
    </ModalPortalErr>
  );
};
export default ModalQuiz;
