import React from "react";
import style from "./Royal.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import { SafePic } from "react-safe-pic";
import imgPng from "../../../images/royal/royal-bg.png";
import imgWebp from "../../../images/royal/royal-bg.webp";
import imgPngMob from "../../../images/royal/royal-bg-mob.png";
import imgPngPhone from "../../../images/royal/royal-phone.png";
import imgPngCoin from "../../../images/royal/royal-coin.png";
import imgPngMaskot from "../../../images/royal/royal-maskot.png";
import imgWebpMaskot from "../../../images/royal/royal-maskot.webp";
import { useSelector } from "react-redux";
import { checkArr } from "../../../service/checkArr";

const Royal = () => {
  const { isMobileOrTablet, topWinningIphone } = useSelector(
    (state) => state.app
  );

  return (
    <section className={clsx(style.royal)}>
      <div className={clsx(style.royal__inner, "container")}>
        <div className={clsx(style.royal__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--royal"]
            )}
          >
            роял степ приз
          </h2>
        </div>

        <div className={clsx(style.royal__content)}>
          <figure className={clsx(style.royal__bg)}>
            {!isMobileOrTablet && (
              <SafePic regularImage={imgPng} webpImage={imgWebp} />
            )}
            {isMobileOrTablet && <SafePic regularImage={imgPngMob} />}
          </figure>

          <figure className={clsx(style.royal__coin)}>
            <SafePic regularImage={imgPngCoin} />
          </figure>

          <figure className={clsx(style.royal__maskot)}>
            <SafePic regularImage={imgPngMaskot} webpImage={imgWebpMaskot} />
          </figure>
          <div className={clsx(style.royal__wrap)}>
            <figure className={clsx(style.royal__phone)}>
              <SafePic regularImage={imgPngPhone} />
            </figure>
            <div className={clsx(style.royal__prize, style.prize)}>
              {checkArr(topWinningIphone) && (
                <>
                  <h3 className={clsx(style.prize__title)}>
                    {topWinningIphone[0].prize.title}{" "}
                  </h3>
                  <p className={clsx(style.prize__name)}>
                    {topWinningIphone[0].winner.name}
                  </p>
                  <p className={clsx(style.prize__tel)}>
                    {topWinningIphone[0].winner.phone}
                  </p>
                </>
              )}

              {!checkArr(topWinningIphone) && (
                <>
                  <h3
                    className={clsx(
                      style.prize__title,
                      style["prize__title--empty"]
                    )}
                  >
                    Розыгрыш победителя состоится 9 января 2024&nbsp;г.
                  </h3>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Royal;
