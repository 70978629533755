import clsx from "clsx";
import React from "react";
import style from "./Logo.module.scss";
import ImgRetina from "../img/ImgRetina";
import imgLogo from "../../../images/header/logo.png";
import imgLogo_2 from "../../../images/header/logo@2x.png";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Logo = ({ parent }) => {
  return (
    <HashLink
      className={clsx(style.logo, style[`logo--${parent}`])}
      to={"/#top"}
      aria-label="logo"
      smooth
    >
      <ImgRetina
        alt={"logo"}
        width={185}
        height={60}
        src={imgLogo}
        srcSet={[imgLogo, imgLogo_2].join(", ")}
      />
    </HashLink>
  );
};
export default Logo;
