import React from "react";
import clsx from "clsx";
import style from "./MyPrizes.module.scss";
import { useDispatch } from "react-redux";
import { stepTo } from "../../../store/appSlice";

import { ReactComponent as CopyIcon } from "../../../images/svg/copy.svg";

const MyPrizeOzon = ({ copy }) => {
  const dispatch = useDispatch();

  const handleCopy = (e) => {
    const input = e.target.previousSibling;
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
    dispatch(stepTo({ type: "private", step: 3 }));
  };
  return (
    <>
      <button
        type="button"
        className={clsx(style.card__link)}
        onClick={() => dispatch(stepTo({ type: "private", step: 2 }))}
      >
        Правила использования
      </button>

      <label className={clsx(style.card__copy, style.copy)}>
        <input
          readOnly
          className={clsx(style.copy__input, style["copy__input--hidden"])}
          value={copy}
          type="text"
          size={1}
        />

        <button
          type="button"
          className={clsx(style.copy__button)}
          onClick={handleCopy}
        >
          <CopyIcon />
        </button>

        <div className={clsx(style.copy__input)}>{copy}</div>
      </label>
    </>
  );
};
export default MyPrizeOzon;
