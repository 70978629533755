import React from "react";
import style from "./Feedback.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import { ReactComponent as CheckIcon } from "../../../images/svg/check.svg";
import { SafePic } from "react-safe-pic";
import imgChock from "../../../images/feedback/feedback-chock.png";
import imgMaskot from "../../../images/feedback/feedback-maskot.png";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  required,
  nameMin,
  valid,
  nameMax,
  requiredCeck,
} from "../../UI/form/errText";
import { onNameInput } from "../../../service/form/mask/name";
import { feedbackSend } from "../../../store/axs/feedback";
import { errorShow } from "../../../store/appSlice";
import { useDispatch, useSelector } from "react-redux";

const Feedback = () => {
  const dispatch = useDispatch();

  const {
    settings: { terms },
    user: { firstName, email },
  } = useSelector((state) => state.app);

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(required)
      .matches(/[^-]$/, valid)
      .min(2, nameMin)
      .max(50, nameMax),

    email: yup
      .string()
      .required(required)
      .matches(/@[^.]*\./, valid)
      .email(valid),

    message: yup.string().required(required),
    agree: yup.boolean().oneOf([true], requiredCeck),
  });

  const formik = useFormik({
    initialValues: {
      firstName: firstName ? firstName : "",
      message: "",
      email: email ? email : "",
      agree: true,
    },
    enableReinitialize: true,

    validationSchema,
    onSubmit: (values, { resetForm }) => {
      feedbackSend(values.email, values.firstName, values.message)
        .then((res) => {
          if (res.status !== 201) {
            throw new Error();
          }
          resetForm();
          dispatch(errorShow("Ваше сообщение успешно отправлено"));
        })
        .catch(() => {});
    },
  });

  return (
    <section id="feedback" className={clsx(style.feedback)}>
      <div className={clsx(style.feedback__inner)}>
        <figure className={clsx(style.feedback__maskot)}>
          <SafePic regularImage={imgMaskot} />
        </figure>
        <div className={clsx(style.feedback__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--feedback"]
            )}
          >
            Напишите нам
          </h2>
        </div>

        <p className={style.feedback__text}>
          Если у Вас остались вопросы, задайте их нам.
        </p>

        <div className={clsx(style.feedback__content)}>
          <figure className={clsx(style.feedback__chock)}>
            <SafePic regularImage={imgChock} />
          </figure>
          <form
            onSubmit={formik.handleSubmit}
            className={clsx(style.feedback__form, "form")}
            action="#"
          >
            <div className="form__block form__block--half">
              <div className="form__field  input-block">
                <label className="input-block__label">
                  <span className="input-block__name">Имя</span>
                  <input
                    type="text"
                    className={clsx(
                      "input-block__input",
                      formik.errors.firstName && "input-block__input--error"
                    )}
                    placeholder="Введите имя"
                    name="firstName"
                    onInput={onNameInput}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </label>
                {formik.errors.firstName ? (
                  <p className="input-block__error">
                    {formik.errors.firstName}
                  </p>
                ) : null}
              </div>

              <div className="form__field input-block">
                <label className="input-block__label">
                  <span className="input-block__name">example@mail.com</span>
                  <input
                    type="text"
                    className={clsx(
                      "input-block__input",
                      formik.errors.email && "input-block__input--error"
                    )}
                    placeholder="example@mail.com"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </label>
                {formik.errors.email ? (
                  <p className="input-block__error">{formik.errors.email}</p>
                ) : null}
              </div>
            </div>

            <div className="form__block">
              <div className="form__field input-block">
                <label className="input-block__label">
                  <span className="input-block__name">Сообщение</span>
                  <textarea
                    className={clsx(
                      "input-block__input",
                      "input-block__textarea",
                      formik.errors.message && "input-block__input--error"
                    )}
                    name="message"
                    cols="1"
                    rows="1"
                    placeholder="Введите сообщение"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                </label>
                {formik.errors.message ? (
                  <p className="input-block__error">{formik.errors.message}</p>
                ) : null}
              </div>
            </div>

            <div className="form__block">
              <div className="form__field">
                <label className="form__label checkbox">
                  <input
                    className="checkbox__input"
                    type="checkbox"
                    name="agree"
                    defaultChecked={true}
                    value={formik.values.agree}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="checkbox__indicator">
                    <CheckIcon />
                  </span>
                  <span className="checkbox__label">
                    <span>Согласен(на) на обработку</span>
                    &nbsp;
                    <a
                      className="link-doc"
                      href={terms}
                      target="_blank"
                      rel="noreferrer"
                    >
                      персональных данных
                    </a>
                  </span>
                </label>
                {formik.errors.agree ? (
                  <p className="input-block__error">{formik.errors.agree}</p>
                ) : null}
              </div>
            </div>

            <button
              className={clsx(style.feedback__btn, "form__button")}
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
            >
              Отправить
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};
export default Feedback;
