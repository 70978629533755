import React from "react";
import style from "./Step.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import styleBtn from "../../UI/btn/Button.module.scss";
import { SafePic } from "react-safe-pic";
import SlotMachine from "./SlotMachine";
import imgPngCoin from "../../../images/step/coin.png";
import imgWebpCoin from "../../../images/step/coin.webp";
import { useDispatch, useSelector } from "react-redux";
import { stepTo } from "../../../store/appSlice";

const Step = () => {
  const dispatch = useDispatch();

  const {
    user: { firstName },

    stateAction,
  } = useSelector((state) => state.app);

  return (
    <section className={clsx(style.step)}>
      <div className={clsx(style.step__inner)}>
        <figure className={clsx(style.step__coin)}>
          <SafePic regularImage={imgPngCoin} webpImage={imgWebpCoin} />
        </figure>

        <div className={clsx(style.step__left)}>
          <div className={clsx(style.step__title, styleTitle.title)}>
            <h2
              className={clsx(
                styleTitle.title__inner,
                styleTitle["title__inner--step"]
              )}
            >
              степ роял
            </h2>
          </div>

          <div className={clsx(style.step__text, style.text)}>
            <p className={clsx(style.text__content)}>
              Каждый загруженный чек дарит тебе шанс покрутить барабан и
              выиграть сертификат Ozon на{" "}
              <span className={clsx(style.text__number)}>500</span>{" "}
              <span className={clsx(style.text__number)}>1000</span> или{" "}
              <span className={clsx(style.text__number)}>3000</span> рублей,
              классный стикерпак в Telegram, а также зажигательный танец Мистера
              Ореха!
            </p>
          </div>

          {stateAction === 0 && (
            <button
              className={clsx(styleBtn.button, style.step__btn)}
              onClick={() =>
                firstName
                  ? dispatch(stepTo({ type: "check", step: 1 }))
                  : dispatch(stepTo({ type: "auth", step: 1 }))
              }
            >
              загрузить чек
            </button>
          )}
        </div>

        <div className={clsx(style.step__right, style.machine)}>
          <SlotMachine parent={"home"} />
        </div>
      </div>
    </section>
  );
};
export default Step;
