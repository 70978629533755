import React from "react";
import style from "./MyPrizes.module.scss";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { stepTo, setWin } from "../../../store/appSlice";
import MyPrizeOzon from "./MyPrizeOzon";

const MuPrizesCard = ({ type, img, title, copy, tgLink, file }) => {
  const dispatch = useDispatch();

  return (
    <div className={clsx(style.card)}>
      <div className={clsx(style.card__inner)}>
        <figure className={clsx(style["card__img-wrap"])}>
          <img src={img} alt="" />
        </figure>

        <div className={clsx(style.card__block)}>
          <h3 className={clsx(style.card__title)}>{title}</h3>
          {type === 2 && <MyPrizeOzon copy={copy} />}
          {type === 3 && <MyPrizeOzon copy={copy} />}
          {type === 4 && <MyPrizeOzon copy={copy} />}

          {type === 1 && (
            <a
              href={tgLink}
              className={clsx(style.card__link)}
              target="_blank"
              rel="noreferrer"
            >
              t.me/pack
            </a>
          )}
          {type === 0 && (
            <button
              type="button"
              className={clsx(style.card__link)}
              onClick={() => {
                dispatch(setWin(file));
                dispatch(stepTo({ type: "quiz", step: 6 }));
              }}
            >
              Посмотреть
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default MuPrizesCard;
