import Cookies from "js-cookie";
const list = ["firstName", "lastName", "email", "age", "gender"];

export const clearCookie = () => {
  list.forEach((item) => {
    Cookies.remove(item);
  });
};

export const setCookies = (values) => {
  list.forEach((item) => {
    Cookies.set(item, values[`${item}`], { expires: 1 });
  });
};
