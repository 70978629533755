/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { required } from "../../UI/form/errText";
import {
  regSendVerificationCode,
  regVerifyCode,
} from "../../../store/axs/registration";
import { stepTo, setVerificationToken } from "../../../store/appSlice";
import { onCodeInput } from "../../../service/form/mask/code";
import { clearCookie } from "./js/setCookies";

const ModalRegistration2 = ({ open }) => {
  const dispatch = useDispatch();

  const {
    user: { phoneNumber, verificationToken },
  } = useSelector((state) => state.app);

  const [isCountDown, setCountDown] = useState({
    isActive: true,
    number: 60,
  });

  const countDownTimer = () => {
    if (isCountDown.number > 0 && isCountDown.isActive) {
      setTimeout(() => {
        setCountDown({
          ...isCountDown,
          number: (isCountDown.number -= 1),
        });
        countDownTimer();
      }, 1000);
    } else {
      setCountDown({
        number: 60,
        isActive: false,
      });
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    countDownTimer();
  }, [open, isCountDown.isActive]);

  useEffect(() => {
    clearCookie();
  }, []);

  const clickHandle = async () => {
    regSendVerificationCode(phoneNumber)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }

        dispatch(setVerificationToken(res.data.verificationToken));
        setCountDown({
          ...isCountDown,
          isActive: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = yup.object({
    code: yup.number().required(required),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },

    validationSchema,
    onSubmit: (values) => {
      regVerifyCode(verificationToken, values.code)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }

          dispatch(stepTo({ type: "registration", step: 3 }));
        })
        .catch((err) => {
          dispatch(stepTo({ type: "registration", step: 2 }));
        });
    },
  });

  return (
    <Modal open={open}>
      <form onSubmit={formik.handleSubmit} className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Регистрация</h2>
        <div className="form__block">
          <div className="form__field  input-block">
            <p className={clsx("input-block__info")}>
              На {phoneNumber ? `номер ${phoneNumber}` : "Ваш номер телефона"}{" "}
              был отправлен код подтверждения.
            </p>
            <p className={clsx("input-block__info")}>
              Введите его, чтобы продолжить регистрацию.
            </p>
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Код</span>
              <input
                id="code"
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.code && "input-block__input--error"
                )}
                placeholder="Введите код из смс"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onInput={onCodeInput}
              />
            </label>
            {formik.errors.code ? (
              <p className="input-block__error">{formik.errors.code}</p>
            ) : null}
            <div className={"input-block__coundown"}>
              {isCountDown.isActive ? (
                <p>
                  Отправить код повторно через&ensp;
                  <span className={"input-block__timer"}>
                    {isCountDown.number > 9
                      ? isCountDown.number
                      : `0${isCountDown.number}`}
                  </span>{" "}
                  сек
                </p>
              ) : (
                <button
                  onClick={clickHandle}
                  className={"input-block__send"}
                  type="button"
                >
                  Отправить код
                </button>
              )}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          disabled={!formik.isValid || !formik.dirty}
        >
          Подтвердить
        </button>
      </form>
    </Modal>
  );
};
export default ModalRegistration2;
