import React from "react";
import ModalQuiz from "../template/ModalQuiz";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import styleBtn from "../../UI/btn/Button.module.scss";
import { SafePic } from "react-safe-pic";
import imgPngTg from "../../../images/quiz/imgPngTg.png";

const ModalQuizTg = ({ open, link }) => {
  return (
    <ModalQuiz open={open}>
      <div className={clsx(style.quiz)}>
        <div className={clsx(style.quiz__inner)}>
          <div className={clsx(style.quiz__title, styleTitle.title)}>
            <h2
              className={clsx(
                styleTitle.title__inner,
                styleTitle["title__inner--quiz"]
              )}
            >
              приз
            </h2>
          </div>

          <p className={clsx(style.quiz__text)}>Стикер-пак в Телеграм!</p>

          <div className={clsx(style.quiz__copy, style.copy)}>
            <a
              href={link}
              target="_blank"
              type="button"
              className={clsx(styleBtn.button)}
              rel="noreferrer"
            >
              t.me/ pack
            </a>
          </div>
        </div>

        <figure className={clsx(style.quiz__maskot, style["quiz__maskot--tg"])}>
          <SafePic regularImage={imgPngTg} />
        </figure>
      </div>
    </ModalQuiz>
  );
};
export default ModalQuizTg;
