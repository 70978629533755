import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { clearAllStep } from "../../../store/appSlice";

const ModalRecovery4 = ({ open }) => {
  const dispatch = useDispatch();
  return (
    <Modal open={open}>
      <div action="" className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Пароль успешно изменен</h2>

        <button
          type="button"
          className={clsx(style.modal__button, "form__button")}
          onClick={() => dispatch(clearAllStep())}
        >
          Отлично
        </button>
      </div>
    </Modal>
  );
};
export default ModalRecovery4;
