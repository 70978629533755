import { store } from "../../../../store";
import { stepTo, setReplica, setWin } from "../../../../store/appSlice";
import { replica_fail, replica_win } from "../../../../service/vairables";

export const makePrizeRersponse = (data) => {
  switch (data.prize.type) {
    case 0:
      // танец
      store.dispatch(setWin(data.prize.file));
      store.dispatch(stepTo({ type: "quiz", step: 6 }));
      store.dispatch(setReplica(replica_win));
      break;
    case 1:
      // стикерпак
      store.dispatch(setWin(data.prize.link));
      store.dispatch(stepTo({ type: "quiz", step: 4 }));
      store.dispatch(setReplica(replica_win));
      break;
    case 2:
      // озон 500
      store.dispatch(stepTo({ type: "quiz", step: 1 }));
      store.dispatch(setReplica(replica_win));
      store.dispatch(setWin(data.promoCodeValue));
      break;
    case 3:
      // озон 1000
      store.dispatch(stepTo({ type: "quiz", step: 2 }));
      store.dispatch(setReplica(replica_win));
      store.dispatch(setWin(data.promoCodeValue));
      break;
    case 4:
      // озон 3000
      store.dispatch(stepTo({ type: "quiz", step: 3 }));
      store.dispatch(setReplica(replica_win));
      store.dispatch(setWin(data.promoCodeValue));
      break;
    case 5:
      store.dispatch(stepTo({ type: "quiz", step: 8 }));
      store.dispatch(setReplica(replica_fail));
      break;
    case 7:
      break;

    default:
      break;
  }
};
