import React, { useEffect } from "react";
import style from "./Uploaded.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import Card from "./Card";
import { ReactComponent as PLusIcon } from "../../../images/svg/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { stepTo, setReceipt } from "../../../store/appSlice";
import { quizGetReceipt } from "../../../store/axs/quiz";

const Uploaded = () => {
  const dispatch = useDispatch();
  const jwt = window.localStorage.getItem("access");

  useEffect(() => {
    quizGetReceipt(jwt).then((res) => {
      try {
        if (res.status !== 200) {
          throw new Error();
        }

        dispatch(setReceipt(res.data.results));
      } catch (error) {}
    });
  }, [dispatch, jwt]);

  const { stateAction, receipt } = useSelector((state) => state.app);

  return (
    <section className={clsx(style.uploaded)}>
      <div className={clsx(style.uploaded__inner, "container")}>
        <div className={clsx(style.uploaded__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--uploaded"]
            )}
          >
            Загруженные чеки
          </h2>
        </div>

        <div
          className={clsx(
            style.uploaded__content,
            Array.isArray(receipt) &&
              receipt.length === 0 &&
              style["uploaded__content--empty"]
          )}
        >
          {stateAction === 0 && (
            <button
              className={clsx(
                style.uploaded__btn,
                style.button,
                Array.isArray(receipt) &&
                  receipt.length === 0 &&
                  style["button--empty"]
              )}
              onClick={() => dispatch(stepTo({ type: "check", step: 1 }))}
            >
              <span className={clsx(style.button__plus)}>
                <PLusIcon />
              </span>
              <span className={clsx(style.button__label)}>Загрузить</span>
            </button>
          )}

          {Array.isArray(receipt) &&
            receipt.map(
              ({ externalId, images, status, uploadDate, rejectReason }, i) => (
                <Card
                  key={i}
                  externalId={externalId}
                  images={images}
                  status={status}
                  uploadDate={uploadDate}
                  rejectReason={rejectReason}
                />
              )
            )}
        </div>
      </div>
    </section>
  );
};
export default Uploaded;
