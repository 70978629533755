import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepTo } from "../../store/appSlice";

import ModalAuth1 from "./auth/ModalAuth1";
import ModalAuth2 from "./auth/ModalAuth2";

import ModalRegistration1 from "./registration/ModalRegistration1";
import ModalRegistration2 from "./registration/ModalRegistration2";
import ModalRegistration3 from "./registration/ModalRegistration3";
import ModalRegistration4 from "./registration/ModalRegistration4";

import ModalRecovery1 from "./recovery/ModalRecovery1";
import ModalRecovery2 from "./recovery/ModalRecovery2";
import ModalRecovery3 from "./recovery/ModalRecovery3";
import ModalRecovery4 from "./recovery/ModalRecovery4";

import ModalCheck1 from "./check/ModalCheck1";
import ModalCheck2 from "./check/ModalCheck2";
import ModalCheck3 from "./check/ModalCheck3";

import ModalPrivate1 from "./private/ModalPrivate1";
import ModalPrivate2 from "./private/ModalPrivate2";
import ModalPrivate3 from "./private/ModalPrivate3";

import ModalQuizOzon500 from "./quiz/ModalQuizOzon500";
import ModalQuizOzon1000 from "./quiz/ModalQuizOzon1000";
import ModalQuizOzon3000 from "./quiz/ModalQuizOzon3000";
import ModalQuizTg from "./quiz/ModalQuizTg";
import ModalQuizDance1 from "./quiz/ModalQuizDance1";
import ModalQuizDance from "./quiz/ModalQuizDance";
import ModalQuizDance3 from "./quiz/ModalQuizDance3";
import ModalQuizFail from "./quiz/ModalQuizFail";
import ModalQuizZero from "./quiz/ModalQuizZero";
import ModalQuizOver from "./quiz/ModalQuizOver";
import ModalQuizStart from "./quiz/ModalQuizStart";

import ModalError from "./error/ModalError";

const ModalManager = ({ modal }) => {
  const dispatch = useDispatch();

  const {
    error: { isError, detail },
    user,
    win,
  } = useSelector((state) => state.app);

  useEffect(() => {
    const el = document.querySelector("#revizor");


    if (el) {
      window.revizorWidget.init("#revizor", {
        apiKey: process.env.REACT_APP_VIDGET_KEY,

        userUuid: user.id,

        successCallback: function () {
          dispatch(stepTo({ type: "check", step: 2 }));
          window.ym(95385999,'reachGoal','upload_check')
        },
        errorCallback: function () {
          dispatch(stepTo({ type: "check", step: 3 }));
        },
        addButtonLabel: "Добавить чек", // text for button 'add', type = string, defaultValue = 'Добавить чек'
        sendButtonLabel: "Отправить чек", // text for button 'send', type = string, defaultValue = 'Отправить чек'
        uploadButtonLabel: "Загрузить", // text for button 'upload', type = string, defaultValue = 'Загрузить чек'
        classForUploadButton: "", // className for buttons to add and upload checks, type = string
        classForUploadPreview: "", // className for block with preview uploaded check, type = string
        classForDeleteButton: "", // className for delete button, type = string
        classForSendButton: "", // className for button to send, type = string
      });
    }
  });

  if (isError) {
    return <ModalError open={true} title={detail} />;
  }
  return (
    <>
      <ModalAuth1 open={modal === "auth-1"} />
      <ModalAuth2 open={modal === "auth-2"} />

      <ModalRegistration1 open={modal === "registration-1"} />
      <ModalRegistration2 open={modal === "registration-2"} />
      <ModalRegistration3 open={modal === "registration-3"} />
      <ModalRegistration4 open={modal === "registration-4"} />

      <ModalRecovery1 open={modal === "recovery-1"} />
      <ModalRecovery2 open={modal === "recovery-2"} />
      <ModalRecovery3 open={modal === "recovery-3"} />
      <ModalRecovery4 open={modal === "recovery-4"} />

      <ModalCheck1 open={modal === "check-1"} />
      <ModalCheck2 open={modal === "check-2"} />
      <ModalCheck3 open={modal === "check-3"} />

      {user.firstName && (
        <ModalPrivate1 open={modal === "private-1"} user={user} />
      )}
      <ModalPrivate2 open={modal === "private-2"} />
      <ModalPrivate3 open={modal === "private-3"} />

      <ModalQuizOzon500 code={win} open={modal === "quiz-1"} />
      <ModalQuizOzon1000 code={win} open={modal === "quiz-2"} />
      <ModalQuizOzon3000 code={win} open={modal === "quiz-3"} />

      <ModalQuizTg link={win} open={modal === "quiz-4"} />

      <ModalQuizDance1 open={modal === "quiz-5"} />
      <ModalQuizDance gif={win} open={modal === "quiz-6"} />
      <ModalQuizDance3 open={modal === "quiz-7"} />

      <ModalQuizFail open={modal === "quiz-8"} />
      <ModalQuizZero open={modal === "quiz-9"} />

      <ModalQuizOver open={modal === "quiz-10"} />
      <ModalQuizStart open={modal === "quiz-11"} />
    </>
  );
};
export default ModalManager;
