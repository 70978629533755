import React, { useState, useEffect } from "react";
import style from "./Step.module.scss";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { SafePic } from "react-safe-pic";
import imgPngMaskot from "../../../images/step/maskot.png";
import imgWebpMaskot from "../../../images/step/maskot.webp";
import imgGame from "../../../images/step/game.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Manipulation } from "swiper/modules";
import { setIsPossible } from "../../../store/appSlice";
import { quizGetIsPossibleStart } from "../../../store/axs/quiz";
import { handleQuiz } from "./js/quiz";
import { getItemSlot } from "./js/getItemSlot";

const SlotMachine = ({ parent }) => {
  const dispatch = useDispatch();
  const {
    replica,
    stateAction,

    isPossible: { attemptsLeft },
  } = useSelector((state) => state.app);
  const jwt = window.localStorage.getItem("access");

  const [swiper, setSwiper] = useState(null);
  const [maskots, setMaskots] = useState(null);

  useEffect(() => {
    setMaskots(getItemSlot());
  }, []);

  useEffect(() => {
    if (!jwt) {
      return;
    }

    // if (!firstName) {
    //   return;
    // }

    quizGetIsPossibleStart(jwt).then((res) => {
      try {
        if (res.status !== 200) {
          throw new Error();
        }

        dispatch(setIsPossible(res.data));
      } catch (error) {}
    });
  }, [dispatch, jwt]);

  return (
    <div className={clsx(style.machine__inner)}>
      {parent === "private" && (
        <p className={clsx(style.machine__count)}>попыток: {attemptsLeft}</p>
      )}

      <div className={clsx(style.machine__info)}>
        <p className={clsx(style.machine__text)}>{replica}</p>

        <div className={clsx(style.machine__balls)}></div>
      </div>
      <figure className={clsx(style.machine__bg)}>
        <SafePic regularImage={imgPngMaskot} webpImage={imgWebpMaskot} />
      </figure>

      <div className={clsx(style.machine__control)}>
        <figure className={clsx(style.machine__game)}>
          <SafePic regularImage={imgGame} />
        </figure>

        <button
          type="button"
          className={clsx(style.machine__go, style.go)}
          onClick={(e) => {
            handleQuiz(e, stateAction, swiper);
          }}
        >
          <figure
            className={clsx(style.go__img, style.show, style["go__img--top"])}
          ></figure>
          <figure
            className={clsx(style.go__img, style["go__img--middle"])}
          ></figure>
          <figure
            className={clsx(style.go__img, style["go__img--down"])}
          ></figure>
        </button>
      </div>

      <div className={clsx(style.machine__content)}>
        <div className={clsx(style.slt, "slt")}>
          <Swiper
            onSwiper={setSwiper}
            allowTouchMove={false}
            speed={50}
            modules={[Autoplay, Manipulation]}
            className={clsx(style.slt__swiper)}
            direction="vertical"
            slidesPerView={3}
            loop={true}
            freeMode={true}
            initialSlide={0}
            // centeredSlides={true}
            slideActiveClass={"slt-active"}
            slidePrevClass={"slt-prev"}
            slideNextClass={"slt-next"}
          >
            {maskots &&
              maskots.map(({ id, list }, i) => (
                <SwiperSlide
                  key={i}
                  className={clsx(
                    style.slot__item,
                    style[`slot__item--${i + 1}`]
                  )}
                  data-id={id}
                >
                  {list.map((data, n) => (
                    <figure
                      key={n}
                      className={clsx(
                        style.slot__img,
                        "slot__img",
                        style[`slot__img--${i + 1}`]
                      )}
                      data-img={data}
                    ></figure>
                  ))}
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default SlotMachine;
