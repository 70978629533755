import React from "react";
import style from "./How.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import { SafePic } from "react-safe-pic";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide from "./Slides";
import imgPngBasket from "../../../images/how/basket.png";
import imgPngHands from "../../../images/how/hands.png";
import imgPngGift from "../../../images/how/gift.png";
import { Pagination } from "swiper/modules";
import styleBtn from "../../UI/btn/Button.module.scss";
import imgCube from "../../../images/how/cube.png";
import imgCandy from "../../../images/how/candy.png";
// import useScreenType from "react-screentype-hook";
import { useDispatch, useSelector } from "react-redux";
import { stepTo } from "../../../store/appSlice";

const How = () => {
  const dispatch = useDispatch();

  const {
    user: { firstName },
    stateAction,
    isMobileOrTablet,
  } = useSelector((state) => state.app);

  // const screenType = useScreenType({
  //   mobile: 320,
  //   tablet: 769,
  // });

  // const { isMobile } = screenType;
  const slides = [
    {
      title: "Купи продукт",
      imgPng: imgPngBasket,
      text: "Покупай с 30 октября по 31 декабря 2023\u00A0г. товар, участвующий в акции",
    },
    {
      title: "Загрузи чек",
      imgPng: imgPngHands,
      text: "Отправь свой чек, соответствующий правилам, на проверку",
    },
    {
      title: "Выиграй приз",
      imgPng: imgPngGift,
      text: "Получи шанс выиграть сертификат Ozon и участвуй в розыгрыше главных призов",
    },
  ];

  return (
    <section id="how" className={clsx(style.how)}>
      <figure className={clsx(style.how__cube)}>
        <SafePic regularImage={imgCube} />
      </figure>
      <figure className={clsx(style.how__candy)}>
        <SafePic regularImage={imgCandy} />
      </figure>
      <div className={clsx(style.how__inner, "container")}>
        <div className={clsx(style.how__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--how"]
            )}
          >
            как принять участие
          </h2>
        </div>

        <Swiper
          init={isMobileOrTablet}
          className={clsx(style.how__swiper)}
          modules={[Pagination]}
          cssMode={true}
          pagination={{
            clickable: true,
            bulletClass: "bullet__item",
            bulletElement: "span",
            bulletActiveClass: "bullet__item--active",
            modifierClass: "how--",
          }}
          spaceBetween={0}
          breakpoints={{
            769: {
              slidesPerView: 3,
              pagination: {
                enabled: false,
              },
            },
            320: {
              slidesPerView: 1,
              pagination: {
                enabled: true,
              },
            },
          }}
          slidesPerView={3}
        >
          {slides.map((item, i) => (
            <SwiperSlide key={i} className={clsx(style.prizes__slide)}>
              <Slide item={item} i={i} />
            </SwiperSlide>
          ))}
        </Swiper>

        {stateAction === 0 && (
          <button
            type="buton"
            className={clsx(styleBtn.button, style.how__btn)}
            onClick={() =>
              firstName
                ? dispatch(stepTo({ type: "check", step: 1 }))
                : dispatch(stepTo({ type: "auth", step: 1 }))
            }
          >
            загрузить чек
          </button>
        )}
      </div>
    </section>
  );
};
export default How;
