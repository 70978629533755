import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { clearAllStep } from "../../../store/appSlice";
import { useDispatch } from "react-redux";

const ModalPrivate2 = ({ open }) => {
  const dispatch = useDispatch();

  return (
    <Modal open={open}>
      <div className={clsx(style.modal__form, style["modal__form--big"])}>
        <h2 className={clsx(style.modal__title)}>Правила использования</h2>

        <div className="form__block">
          <div className="form__field ozon">
            <div className="ozon__block">
              <h3 className="ozon__subtitle">
                Как воспользоваться сертификатом, если вам его подарили
              </h3>
              <p className="ozon__text">
                У каждого сертификата Ozon есть код активации. Это уникальная
                комбинация из букв и цифр. Чтобы активировать сертификат, нужно
                указать этот код в личном кабинете.
              </p>
              <p className="ozon__text">
                Перейдите в личный кабинет на сайте или в приложении Ozon.
                Выберите раздел «Кодовые слова…». Укажите код активации и
                нажмите «Применить». Через 10-15 минут номинал начислится на ваш
                Баланс средств. Средства будут доступны три года. Тратьте их на
                покупки!
              </p>
            </div>

            <div className="ozon__block">
              <h3 className="ozon__subtitle">Важные моменты</h3>
              <p className="ozon__text">
                За сутки можно активировать максимум три подарочных сертификата.
              </p>
              <p className="ozon__text">
                На сумму сертификата можно заказывать всё, кроме товаров из
                разделов «Цифровые книги», «Подарочные сертификаты», «Авиа и
                Ж/Д-билеты».
              </p>
              <p className="ozon__text">
                Приобретать сертификаты могут физлица и юрлица, а
                воспользоваться – только физлица.
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          className={clsx(style.modal__button, "form__button")}
          onClick={() => {
            dispatch(clearAllStep());
          }}
        >
          Понятно
        </button>
      </div>
    </Modal>
  );
};
export default ModalPrivate2;
