import React, { useState } from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { stepTo, setUser, errorClose } from "../../../store/appSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  required,
  nameMin,
  valid,
  nameMax,
  passwordMin,
  passwordMax,
  confrmPasswordMatch,
} from "../../UI/form/errText";
import { onNameInput } from "../../../service/form/mask/name";
import { onAgeInput } from "../../../service/form/mask/age";
import { parse, isDate } from "date-fns";
import { regSaveProfile } from "../../../store/axs/registration";
import { ReactComponent as IconEye } from "../../../images/svg/eye.svg";
import { ReactComponent as IconEyeOff } from "../../../images/svg/eye-off.svg";
import { meGetUserMe } from "../../../store/axs/me";
import Cookies from "js-cookie";
import { setCookies, clearCookie } from "./js/setCookies";

const ModalRegistration3 = ({ open }) => {
  const [isTypeText, setType] = useState(false);
  const dispatch = useDispatch();
  const {
    user: { verificationToken },
  } = useSelector((state) => state.app);

  const today = new Date();

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(required)
      .matches(/[^-]$/, valid)
      .min(2, nameMin)
      .max(50, nameMax),

    lastName: yup
      .string()
      .required(required)
      .matches(/[^-]$/, valid)
      .min(2, nameMin)
      .max(50, nameMax),

    age: yup
      .date()
      .required(required)
      .transform((_, originalValue) => {
        if (originalValue.length < 9) {
          return parse("01.01.1900", "dd.MM.yyyy", new Date());
        }
        const parsedDate = isDate(originalValue)
          ? originalValue
          : parse(originalValue, "dd.MM.yyyy", new Date());
        return parsedDate;
      })
      .max(today, valid),

    email: yup
      .string()
      .required(required)
      .matches(/@[^.]*\./, valid)
      .email(valid),

    password: yup
      .string()
      .required(required)
      .min(8, passwordMin)
      .max(14, passwordMax),

    confirm_password: yup
      .string()
      .required(required)
      .oneOf([yup.ref("password"), null], confrmPasswordMatch),
  });

  const formik = useFormik({
    initialValues: {
      firstName: Cookies.get("firstName") ? Cookies.get("firstName") : "",
      lastName: Cookies.get("lastName") ? Cookies.get("lastName") : "",
      age: Cookies.get("age") ? Cookies.get("age") : "",
      email: Cookies.get("email") ? Cookies.get("email") : "",
      password: "",
      confirm_password: "",
      gender: Cookies.get("gender") ? Cookies.get("gender") : "man",
    },

    validationSchema,
    onSubmit: (values) => {
      setCookies(values);

      regSaveProfile(
        verificationToken,
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.gender,
        values.age.split(".").reverse().join("-")
      )
        .then((res) => {
          if (res.status !== 201) {
            throw new Error();
          }
          window.localStorage.clear();
          window.localStorage.setItem("access", res.data.access);
          meGetUserMe(res.data.access).then((res) => {
            if (res.status !== 200) {
              throw new Error();
            }
            dispatch(setUser(res.data));
            dispatch(errorClose());
            dispatch(stepTo({ type: "registration", step: 4 }));
            clearCookie();
          });
        })
        .catch((err) => {
          dispatch(stepTo({ type: "registration", step: 3 }));
        });
    },
  });

  return (
    <Modal open={open}>
      <form
        onSubmit={formik.handleSubmit}
        className={clsx(style.modal__form, style["modal__form--big"])}
      >
        <h2 className={clsx(style.modal__title)}>Регистрация</h2>

        <div className="form__block">
          <div className="form__field  input-block">
            <p className={clsx("input-block__info")}>
              Все поля обязательны для заполнения
            </p>
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Имя</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.firstName && "input-block__input--error"
                )}
                placeholder="Введите свое имя"
                name="firstName"
                onInput={onNameInput}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.firstName ? (
              <p className="input-block__error">{formik.errors.firstName}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Фамилия</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.lastName && "input-block__input--error"
                )}
                placeholder="Введите свою фамилию"
                name="lastName"
                onInput={onNameInput}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.lastName ? (
              <p className="input-block__error">{formik.errors.lastName}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block radio">
          <span className="radio__title">Пол</span>
          <div className="radio__content">
            <label className="radio__label">
              <span className="radio__name">Мужской</span>
              <input
                type="radio"
                className="radio__input"
                value="man"
                name="gender"
                defaultChecked={
                  Cookies.get("gender") === "woman" ? false : true
                }
                onChange={formik.getFieldProps("gender").onChange}
              />
              <span className="radio__indicator"></span>
            </label>

            <label className="radio__label">
              <span className="radio__name">Женский</span>
              <input
                type="radio"
                className="radio__input"
                value="woman"
                name="gender"
                defaultChecked={
                  Cookies.get("gender") === "woman" ? true : false
                }
                onChange={formik.getFieldProps("gender").onChange}
              />
              <span className="radio__indicator"></span>
            </label>
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Возраст</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.age && "input-block__input--error"
                )}
                placeholder="Дата рождения"
                name="age"
                onInput={onAgeInput}
                value={formik.values.age}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.age ? (
              <p className="input-block__error">{formik.errors.age}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Email</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.email && "input-block__input--error"
                )}
                placeholder="example@mail.ru"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.email ? (
              <p className="input-block__error">{formik.errors.email}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Пароль</span>
              <input
                type={isTypeText ? "text" : "password"}
                className={clsx(
                  "input-block__input",
                  "input-block__input--eye",
                  formik.errors.password && "input-block__input--error"
                )}
                placeholder="Придумайте пароль"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <button
                type="button"
                className="input-block__eye"
                onClick={() => setType(!isTypeText)}
              >
                {!isTypeText && <IconEye />}
                {isTypeText && <IconEyeOff />}
              </button>
            </label>
            {formik.errors.password ? (
              <p className="input-block__error">{formik.errors.password}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Повторите пароль</span>
              <input
                type={isTypeText ? "text" : "password"}
                className={clsx(
                  "input-block__input",
                  "input-block__input--eye",
                  formik.errors.confirm_password && "input-block__input--error"
                )}
                placeholder="Повторите пароль"
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <button
                type="button"
                className="input-block__eye"
                onClick={() => setType(!isTypeText)}
              >
                {!isTypeText && <IconEye />}
                {isTypeText && <IconEyeOff />}
              </button>
            </label>
            {formik.errors.confirm_password ? (
              <p className="input-block__error">
                {formik.errors.confirm_password}
              </p>
            ) : null}
          </div>
        </div>

        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          disabled={!formik.isValid || !formik.dirty}
        >
          Продолжить
        </button>
      </form>
    </Modal>
  );
};
export default ModalRegistration3;
