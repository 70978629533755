/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import btnIconStyle from "../../UI/btn/ButtonIcon.module.scss";
import { ReactComponent as Icon } from "../../../images/winner/arr.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { feedbackFaq } from "../../../store/axs/feedback";
import { setFaq } from "../../../store/appSlice";

const Head = ({ label }) => {
  return (
    <div className={"accordeon__head"}>
      <p className={"accordeon__title"}>{label}</p>

      <div
        className={clsx(
          btnIconStyle.button,
          btnIconStyle["button--lg"],
          btnIconStyle["button--default"],
          "accordeon__btn"
        )}
      >
        <Icon />
      </div>
    </div>
  );
};

const Accordeon = () => {
  const dispatch = useDispatch();

  const addBr = (text) => {
    return text.split("<br>");
  };
  const textChange = (text) => {
    return text.map((item) => {
      return {
        question: item.question,

        answer: addBr(item.answer),
      };
    });
  };

  const { faq } = useSelector((state) => state.app);

  useEffect(() => {
    feedbackFaq(20, 0).then((res) => {
      if (res.status !== 200) {
        throw new Error();
      }

      dispatch(setFaq(textChange(res.data.results)));
    });
  }, [dispatch]);

  return (
    <Accordion className="accordeon" transition transitionTimeout={500}>
      {faq &&
        faq.map(({ question, answer }, i) => (
          <AccordionItem header={<Head label={question} />} key={i}>
            {answer.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </AccordionItem>
        ))}
    </Accordion>
  );
};
export default Accordeon;
