import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";

const ModalCheck3 = ({ open }) => {
  return (
    <Modal open={open}>
      <div className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>
          Произошла ошибка при отправке чека
        </h2>
      </div>
    </Modal>
  );
};
export default ModalCheck3;
