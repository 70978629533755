import React, { useEffect } from "react";
import stylePromo from "../promo/Promo.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { stepTo, resetUser, setIsPossible } from "../../../store/appSlice";
import { Link } from "react-router-dom";
import { quizGetIsPossibleStart } from "../../../store/axs/quiz";
import SlotMachine from "../../home/step/SlotMachine";

const Step = () => {
  const dispatch = useDispatch();
  const jwt = window.localStorage.getItem("access");

  const {
    user: { firstName, lastName, email, phoneNumber },
  } = useSelector((state) => state.app);

  useEffect(() => {
    // if (!firstName) {
    //   return;
    // }
    quizGetIsPossibleStart(jwt).then((res) => {
      try {
        if (res.status !== 200) {
          throw new Error();
        }

        dispatch(setIsPossible(res.data));
      } catch (error) {}
    });
  }, [dispatch, jwt]);

  return (
    <section className={clsx(stylePromo.step)}>
      <div className={clsx(stylePromo.promo__inner)}>
        <div className={clsx(stylePromo.promo__left)}>
          <div className={clsx(stylePromo.promo__title, styleTitle.title)}>
            <h2
              className={clsx(
                styleTitle.title__inner,
                styleTitle["title__inner--promo"]
              )}
            >
              Личный кабинет
            </h2>
          </div>

          <div className={clsx(stylePromo.promo__user, stylePromo.user)}>
            <h3 className={clsx(stylePromo.user__name)}>
              <span>{firstName}</span>
              <span>{lastName}</span>
            </h3>

            <div
              className={clsx(
                stylePromo.user__block,
                stylePromo["user__block--half"],
                stylePromo["user__block--info"]
              )}
            >
              <div className={clsx(stylePromo.user__info, stylePromo.info)}>
                <h4 className={clsx(stylePromo.info__name)}>Телефон</h4>
                <p className={clsx(stylePromo.info__label)}>{phoneNumber}</p>
              </div>
              <div className={clsx(stylePromo.user__info, stylePromo.info)}>
                <h4 className={clsx(stylePromo.info__name)}>Email</h4>
                <p className={clsx(stylePromo.info__label)}>{email}</p>
              </div>
            </div>

            <div
              className={clsx(
                stylePromo.user__block,
                stylePromo["user__block--half"]
              )}
            >
              <button
                type="button"
                className={clsx(
                  "form__button",
                  "form__button--default",
                  stylePromo["user__btn"]
                )}
                onClick={() => dispatch(stepTo({ type: "private", step: 1 }))}
              >
                Изменить данные
              </button>

              <button
                type="button"
                className={clsx(
                  "form__button",
                  "form__button--default",
                  stylePromo["user__btn"]
                )}
                onClick={() => dispatch(stepTo({ type: "recovery", step: 3 }))}
              >
                Изменить пароль
              </button>
            </div>

            <div
              className={clsx(
                stylePromo.user__block,
                stylePromo["user__block--exit"]
              )}
            >
              <Link
                to={"/"}
                className={clsx(
                  "form__button",
                  "form__button--default",
                  stylePromo["user__btn"]
                )}
                onClick={() => {
                  window.localStorage.clear();
                  dispatch(resetUser());
                }}
              >
                выйти
              </Link>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            stylePromo.step__right,
            stylePromo.machine,
            stylePromo.machine__priv
          )}
        >
          <SlotMachine parent={"private"} />
        </div>
      </div>
    </section>
  );
};
export default Step;
