import React from "react";
import style from "./MultyWin.module.scss";
import clsx from "clsx";
import Winner from "../winner/Winner";
import Royal from "../royal/Royal";

const MultyWin = () => {
  return (
    <div className={clsx(style.multy, style["multy--win"])}>
      <Winner />
      <Royal />
    </div>
  );
};
export default MultyWin;
