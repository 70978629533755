/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import clsx from "clsx";
import style from "./Prizes.module.scss";
import styleTitle from "../../UI/title/Title.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide from "./Slides";
import imgPngPhone from "../../../images/prizes/phone.png";
import imgPngTablet from "../../../images/prizes/tablet.png";
import imgPngCards from "../../../images/prizes/cards.png";
import { Pagination } from "swiper/modules";
import { useSelector } from "react-redux";

const Prizes = () => {
  const { isMobileOrTablet } = useSelector((state) => state.app);

  const slides = [
    {
      marquee: "Супер роял приз",
      title: ["Apple", "iPhone 15 pro"],
      imgPng: imgPngPhone,
    },
    {
      marquee: "Каждую неделю",
      title: ["Apple", "iPad 10.9"],
      imgPng: imgPngTablet,
    },
    {
      marquee: "Каждый день",
      title: ["Сертификаты", "OZON"],
      imgPng: imgPngCards,
    },
  ];
  return (
    <section id="prizes" className={clsx(style.prizes)}>
      <div className={clsx(style.prizes__inner, "container")}>
        <div className={clsx(style.prizes__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--prizes"]
            )}
          >
            призы
          </h2>
        </div>

        <Swiper
          init={isMobileOrTablet}
          className={clsx(style.prizes__swiper)}
          modules={[Pagination]}
          cssMode={true}
          pagination={{
            clickable: true,
            bulletClass: "bullet__item",
            bulletElement: "span",
            bulletActiveClass: "bullet__item--active",
            modifierClass: "prizes--",
          }}
          spaceBetween={0}
          breakpoints={{
            769: {
              slidesPerView: 3,
              pagination: {
                enabled: false,
              },
            },
            320: {
              slidesPerView: 1,
              pagination: {
                enabled: true,
              },
            },
          }}
          slidesPerView={3}
        >
          {slides.map((item, i) => (
            <SwiperSlide key={i} className={clsx(style.prizes__slide)}>
              <Slide item={item} i={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};
export default Prizes;
