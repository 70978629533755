/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import style from "./Promo.module.scss";
import clsx from "clsx";
import styleBtn from "../../UI/btn/Button.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { stepTo } from "../../../store/appSlice";

const CountDown = () => {
  const dispatch = useDispatch();

  const {
    user: { firstName },
    stateAction,
    actualTime,
  } = useSelector((state) => state.app);

  const { days, hours, minutes } = actualTime;

  const numList_1 = ["1"];
  const numList_2 = ["2", "3", "4"];
  const numList_3 = ["0", "5", "6", "7", "8", "9"];

  useEffect(() => {
    if (!days) {
      return;
    }
    getDayWord(days[1]);
  }, [days]);

  const getDayWord = (num) => {
    if (numList_1.includes(num)) {
      return "день";
    } else if (numList_2.includes(num)) {
      return "дня";
    } else if (numList_3.includes(num)) {
      return "дней";
    }
    return "дней";
  };

  const getMinuteWord = (num) => {
    if (numList_1.includes(num)) {
      return "минута";
    } else if (numList_2.includes(num)) {
      return "минуты";
    } else if (numList_3.includes(num)) {
      return "минут";
    }

    return "минут";
  };

  return (
    <div
      className={clsx(
        style.promo__content,
        stateAction === 1 && style["promo__content--end"]
      )}
    >
      <div className={clsx(style.count)}>
        {stateAction === -1 && (
          <p className={clsx(style.count__title)}>До начала акции</p>
        )}

        {stateAction === 0 && (
          <p className={clsx(style.count__title)}>до окончания акции</p>
        )}

        {stateAction === 1 && (
          <p className={clsx(style.count__title)}>Акция завершена</p>
        )}

        {stateAction <= 0 && (
          <div className={clsx(style.count__block, style.digits)}>
            <div className={clsx(style.digits__column)}>
              <div className={clsx(style.digits__inner)}>
                <span className={clsx(style.digits__num)}>
                  {days && days[0]}
                </span>
                <span className={clsx(style.digits__num)}>
                  {days && days[1]}
                </span>
              </div>
              {days && (
                <p className={clsx(style.digits__title)}>
                  {getDayWord(days[1])}
                </p>
              )}
            </div>
            <div className={clsx(style.digits__column)}>
              <div className={clsx(style.digits__inner)}>
                <span className={clsx(style.digits__num)}>
                  {hours && hours[0]}
                </span>
                <span className={clsx(style.digits__num)}>
                  {hours && hours[1]}
                </span>
              </div>
              <p className={clsx(style.digits__title)}>часов</p>
            </div>
            <div className={clsx(style.digits__column)}>
              <div className={clsx(style.digits__inner)}>
                <span className={clsx(style.digits__num)}>
                  {minutes && minutes[0]}
                </span>
                <span className={clsx(style.digits__num)}>
                  {minutes && minutes[1]}
                </span>
              </div>

              {minutes && (
                <p className={clsx(style.digits__title)}>
                  {getMinuteWord(minutes[1])}
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      {stateAction === 0 && (
        <button
          className={clsx(styleBtn.button, style.promo__btn)}
          onClick={() =>
            firstName
              ? dispatch(stepTo({ type: "check", step: 1 }))
              : dispatch(stepTo({ type: "auth", step: 1 }))
          }
        >
          участвовать
        </button>
      )}
    </div>
  );
};

export default CountDown;
