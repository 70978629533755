import { store } from "../../../../store";
import { stepTo, setIsPossible } from "../../../../store/appSlice";
import {
  quizGetIsPossibleStart,
  quizGetDrawPrize,
} from "../../../../store/axs/quiz";
import { animationBtn, sleep } from "./animationBtn";
import { slotMove } from "./slotMove";
import { makePrizeRersponse } from "./prizeRersponse";

export const handleQuiz = async (e, stateAction, swiper) => {
  const jwt = window.localStorage.getItem("access");
  if (stateAction > 0) {
    store.dispatch(stepTo({ type: "quiz", step: 10 }));
    return;
  }

  if (stateAction < 0) {
    store.dispatch(stepTo({ type: "quiz", step: 11 }));
    return;
  }

  if (jwt) {
    quizGetIsPossibleStart(jwt).then((res) => {
      if (res.status !== 200) {
        throw new Error();
      }

      if (!res.data.drawAvailable) {
        store.dispatch(stepTo({ type: "quiz", step: 9 }));
        return;
      }

      quizGetDrawPrize(jwt)
        .then(async (res) => {
          if (res.status !== 200) {
            throw new Error();
          }

          animationBtn(e);
          // slotStart(swiper);
          // swiper.params.autoplay.delay = 10;
          // swiper.autoplay.start();
          await sleep(200);
          await slotMove(swiper, res.data.prize.type);
          makePrizeRersponse(res.data);

          // обновляем попытки
          quizGetIsPossibleStart(jwt).then((r) => {
            if (r.status !== 200) {
              throw new Error();
            }

            store.dispatch(setIsPossible(r.data));
          });
        })

        .catch(() => {
          // slotStop(swiper);
        });
    });
  } else {
    store.dispatch(stepTo({ type: "auth", step: 1 }));
  }
};
