import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Template from "./components/template/Template.jsx";
import HomePage from "./pages/homepage/HomePage.jsx";
import Private from "./pages/private/Private.jsx";
import { useDispatch } from "react-redux";
import {
  clearAllStep,
  errorClose,
  setSettings,
  setUser,
  setStateAction,
  setActualTime,
  setIsMobileOrTablet,
} from "./store/appSlice.js";
import { generalSettings } from "./store/axs/general.js";
import { meGetUserMe } from "./store/axs/me.js";
import {
  intervalToDuration,
  formatDistanceStrict,
  startOfDay,
  endOfDay,
  isAfter,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useMediaQuery } from "react-responsive"; // разрешение экрана

function App() {
  const modaClass = "modal-js";
  const modaClassErr = "modal-js-err";
  const dispatch = useDispatch();
  const jwt = window.localStorage.getItem("access");

  const isMobileOrTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    dispatch(setIsMobileOrTablet(isMobileOrTablet));
  }, [dispatch, isMobileOrTablet]);

  const [mainDates, setMainDates] = useState({
    startDate: null,
    endDate: null,
  });

  const { startDate, endDate } = mainDates;

  const getStartDay = (date) => {
    return startOfDay(new Date(Date.parse(date)));
  };

  const getEndDay = (date) => {
    return endOfDay(new Date(Date.parse(date)));
  };

  const makeInterval = (start, end) => {
    const { months, days, hours, minutes } = intervalToDuration({
      start: start,
      end: end,
    });

    const resultDays = formatDistanceStrict(start, end, {
      unit: "day",
    }).replace(/[ a-z]/g, "");

    const getVal = (val) => {
      let data;
      if (Number(val) > 9) {
        data = val.toString().split("");
      } else {
        data = `0${val.toString().split("")}`.split("");
      }

      return data;
    };

    dispatch(
      setActualTime({
        days: months > 0 ? getVal(resultDays) : getVal(days),
        hours: getVal(hours),
        minutes: getVal(minutes),
      })
    );
  };

  // const actualResizeHandler = () => {
  //   window.location.reload(true);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", actualResizeHandler, false);
  //   window.addEventListener("orientationchange ", actualResizeHandler, false);
  //   window.screen.orientation.addEventListener("change", (event) => {
  //     actualResizeHandler();
  //   });

  //   return () => {
  //     window.removeEventListener("resize", actualResizeHandler, false);
  //     window.removeEventListener(
  //       "orientationchange",
  //       actualResizeHandler,
  //       false
  //     );
  //   };
  // }, []);

  useEffect(() => {
    if (!startDate && !endDate) {
      return;
    }

    const startDayStartAction = getStartDay(startDate);
    const endDayEndAction = getEndDay(endDate);
    const current = utcToZonedTime(new Date(), "Europe/Moscow");

    if (isAfter(current, startDayStartAction)) {
      // акция идет
      dispatch(setStateAction(0));
      makeInterval(current, endDayEndAction);
    }
    if (isAfter(startDayStartAction, current)) {
      // не началась
      dispatch(setStateAction(-1));
      makeInterval(current, startDayStartAction);
    }
    if (isAfter(current, endDayEndAction)) {
      // закончилась
      dispatch(setStateAction(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDates]);

  const windowClickEvent = async (e) => {
    if (e.target.classList.contains(modaClass)) {
      dispatch(clearAllStep());
      return;
    }

    if (e.target.classList.contains(modaClassErr)) {
      dispatch(errorClose());
      return;
    }
  };

  useEffect(() => {
    if (!jwt) {
      return;
    }

    meGetUserMe(jwt).then((res) => {
      try {
        dispatch(setUser(res.data));
        dispatch(errorClose());
      } catch (error) {}
    });
  }, [dispatch, jwt]);

  useEffect(() => {
    generalSettings().then((res) => {
      const { startDate, endDate } = res.data;
      dispatch(setSettings(res.data));
      setMainDates({ startDate, endDate });
    });
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("click", windowClickEvent, false);

    return () => {
      window.removeEventListener("click", windowClickEvent, false);
    };
  });

  return (
    <Routes>
      <Route path="/" element={<Template />}>
        <Route index element={<HomePage />} />
        <Route path="/private" element={<Private />} />
      </Route>
    </Routes>
  );
}

export default App;
