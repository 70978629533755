import React, { useEffect, useState } from "react";
import styleLink from "../UI/links/Link.module.scss";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Links = ({ setMenu }) => {
  const { pathname } = useLocation();
  const [isPrivate, setPrivate] = useState(false);

  useEffect(() => {
    if (pathname === "/private") {
      setPrivate(pathname);
    }
  }, [pathname]);

  const links = [
    { label: "Призы", path: "#prizes", anchor: true },
    { label: "Как принять участие", path: "#how", anchor: true },
    { label: "Победители", path: "#winner", anchor: true },
    { label: "FAQ", path: "#faq", anchor: true },
    { label: "Обратная связь", path: "#feedback", anchor: true },
  ];

  return (
    <>
      {!isPrivate &&
        links.map((item, i) => (
          <HashLink
            key={i}
            className={clsx(styleLink.link)}
            smooth
            to={`${item.path}`}
            onClick={() => {
              setMenu(false);
            }}
          >
            {item.label}
          </HashLink>
        ))}

      {isPrivate &&
        links.map((item, i) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <HashLink
            key={i}
            className={clsx(styleLink.link)}
            smooth
            to={`/${item.path}`}
            onClick={() => {
              setMenu(false);
            }}
          >
            {item.label}
          </HashLink>
        ))}
      {/* {isPrivate &&
        links.map((item, i) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key={i} className={clsx(styleLink.link)}>
            {item.label}
          </a>
        ))} */}
    </>
  );
};

export default Links;
