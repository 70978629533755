import React from "react";
import style from "./How.module.scss";
import clsx from "clsx";
import { SafePic } from "react-safe-pic";

const Slide = ({ item, i }) => {
  return (
    <div className={clsx(style.slide, style[`slide--${i + 1}`])}>
      <figure className={clsx(style.slide__img)}>
        <SafePic regularImage={item.imgPng} webpImage={item.imgWebp} />
        <div className={clsx(style.slide__num)}>{i + 1}</div>
      </figure>

      <h3 className={clsx(style.slide__title)}>
        <span>{item.title}</span>
      </h3>

      <p className={clsx(style.slide__text)}>{item.text}</p>
    </div>
  );
};
export default Slide;
