import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clearAllStep } from "../../../store/appSlice";

const ModalCheck2 = ({ open }) => {
  const dispatch = useDispatch();
  return (
    <Modal open={open}>
      <div className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Чек отправлен</h2>

        <p className={clsx("input-block__info")}>
          Ваш чек отправлен на модерацию.
        </p>
        <p className={clsx("input-block__info")}>
          Отслеживать статусы модерации чека вы можете в личном кабинете.
        </p>

        {/* <button
          type="button"
          className={clsx(style.modal__button, "form__button")}
          onClick={() => dispatch(clearAllStep())}
        >
          Понятно
        </button> */}
        <Link
          to={"/private"}
          className={clsx(style.modal__button, "form__button")}
          onClick={() => dispatch(clearAllStep())}
        >
          Личный кабинет
        </Link>
      </div>
    </Modal>
  );
};
export default ModalCheck2;
