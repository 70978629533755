import React, { useState } from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  required,
  passwordMin,
  passwordMax,
  confrmPasswordMatch,
} from "../../UI/form/errText";
import { ReactComponent as IconEye } from "../../../images/svg/eye.svg";
import { ReactComponent as IconEyeOff } from "../../../images/svg/eye-off.svg";
import { mePatchPassChange } from "../../../store/axs/me";
import { useDispatch } from "react-redux";
import { errorShow, clearAllStep } from "../../../store/appSlice";

const ModalRecovery3 = ({ open }) => {
  const jwt = window.localStorage.getItem("access");
  const dispatch = useDispatch();
  const [isTypeText, setType] = useState(false);
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(required)
      .min(8, passwordMin)
      .max(14, passwordMax),

    confirm_password: yup
      .string()
      .required(required)
      .oneOf([yup.ref("password"), null], confrmPasswordMatch),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },

    validationSchema,
    onSubmit: (values) => {
      mePatchPassChange(jwt, values.password).then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }
        dispatch(errorShow("Ваши изменения успешно отправлены"));
        dispatch(clearAllStep());
      });
    },
  });
  return (
    <Modal open={open}>
      <form onSubmit={formik.handleSubmit} className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Изменение пароля</h2>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Пароль</span>
              <input
                type={isTypeText ? "text" : "password"}
                className={clsx(
                  "input-block__input",
                  "input-block__input--eye",
                  formik.errors.password && "input-block__input--error"
                )}
                placeholder="Введите пароль"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <button
                className="input-block__eye"
                onClick={() => setType(!isTypeText)}
              >
                {!isTypeText && <IconEye />}
                {isTypeText && <IconEyeOff />}
              </button>
            </label>
            {formik.errors.password ? (
              <p className="input-block__error">{formik.errors.password}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Повторите пароль</span>
              <input
                type={isTypeText ? "text" : "password"}
                className={clsx(
                  "input-block__input",
                  "input-block__input--eye",
                  formik.errors.confirm_password && "input-block__input--error"
                )}
                placeholder="Введите пароль"
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <button
                className="input-block__eye"
                onClick={() => setType(!isTypeText)}
              >
                {!isTypeText && <IconEye />}
                {isTypeText && <IconEyeOff />}
              </button>
            </label>
            {formik.errors.confirm_password ? (
              <p className="input-block__error">
                {formik.errors.confirm_password}
              </p>
            ) : null}
          </div>
        </div>

        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          disabled={!formik.isValid || !formik.dirty}
        >
          Изменить
        </button>
      </form>
    </Modal>
  );
};
export default ModalRecovery3;
