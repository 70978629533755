import React, { useState } from "react";
import style from "./Uploaded.module.scss";
import clsx from "clsx";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { ReactComponent as IconEye } from "../../../images/svg/eye.svg";
import { ReactComponent as IconInfo } from "../../../images/svg/info.svg";
import { format } from "date-fns";

const Card = ({ externalId, images, status, uploadDate, rejectReason }) => {
  const [isReason, setReasin] = useState(false);
  const getImages = (list) => {
    return list.map((item) => {
      return {
        src: item,
      };
    });
  };

  const getStatus = (status) => {
    let text = "";
    switch (status) {
      case 0: {
        text = "На модерации";
        break;
      }
      case 1: {
        text = "На модерации";
        break;
      }

      case 3: {
        text = "Принят";
        break;
      }
      case 4: {
        text = "Отклонен";
        break;
      }
      case 5: {
        text = "Выиграл";
        break;
      }
      default:
        text = "Принят";
    }

    return text;
  };

  const [open, setOpen] = React.useState(false);

  return (
    <div className={clsx(style.card)}>
      <div className={clsx(style.card__inner)}>
        <div className={clsx(style.card__head, style.head)}>
          <span className={clsx(style.head__num)}>{externalId}</span>
          <span className={clsx(style.head__date)}>
            {format(new Date(uploadDate), "dd.MM.yyyy, HH.mm")}
          </span>
        </div>
        <button
          className={clsx(style.card__btn)}
          type="button"
          onClick={() => (isReason ? setReasin(false) : setOpen(true))}
        >
          <div className={clsx(style.card__eye)}>
            <IconEye />
          </div>
          <figure className={clsx(style["card__img-wrap"])}>
            <img
              className={clsx(style["card__img"])}
              src={images[0]}
              alt="чек"
            />
          </figure>

          {status === 4 && isReason && (
            <div
              className={clsx(style.reason)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className={clsx(style.reason__text)}>{rejectReason}</p>
            </div>
          )}
        </button>
        <div
          className={clsx(
            style.card__status,
            style.status,
            style[`status--${status}`]
          )}
        >
          <p className={clsx(style.status__text)}>{getStatus(status)}</p>

          {status === 4 && (
            <span
              className={clsx(style.status__info)}
              onMouseEnter={() => setReasin(true)}
              onMouseLeave={() => setReasin(false)}
              onClick={() => console.log("object")}
            >
              <IconInfo />
            </span>
          )}
        </div>
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={getImages(images)}
        />
      </div>
    </div>
  );
};
export default Card;
