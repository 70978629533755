import React, { useEffect } from "react";
import style from "./MyPrizes.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import MuPrizesCard from "./MuPrizesCard";

import { quizGetWinningPrizes } from "../../../store/axs/quiz";
import { setMyPrizes, stepTo } from "../../../store/appSlice";
import { useDispatch, useSelector } from "react-redux";
import styleBtn from "../../UI/btn/Button.module.scss";

const MyPrizes = () => {
  const jwt = window.localStorage.getItem("access");
  const dispatch = useDispatch();

  const { myPrizes, stateAction } = useSelector((state) => state.app);

  // console.log(myPrizes);

  useEffect(() => {
    quizGetWinningPrizes(jwt).then((res) => {
      try {
        if (res.status !== 200) {
          throw new Error();
        }

        dispatch(setMyPrizes(res.data));
      } catch (error) {}
    });
  }, [dispatch, jwt]);

  return (
    <section className={clsx(style.myprizes)}>
      <div className={clsx(style.myprizes__inner, "container")}>
        <div className={clsx(style.myprizes__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--myprizes"]
            )}
          >
            мои призы
          </h2>
        </div>

        {Array.isArray(myPrizes.results) && !myPrizes.results.length > 0 && (
          <div className={clsx(style.empty)}>
            <p className={clsx(style.empty__text)}>
              Призов пока что нет, загрузи свой чек!
            </p>

            {stateAction === 0 && (
              <button
                className={clsx(styleBtn.button, style.step__btn)}
                onClick={() => dispatch(stepTo({ type: "check", step: 1 }))}
              >
                загрузить чек
              </button>
            )}
          </div>
        )}

        <div className={clsx(style.myprizes__content)}>
          {Array.isArray(myPrizes.results) &&
            myPrizes.results.length > 0 &&
            myPrizes.results.map(
              (
                { prize: { type, image, title, link, file }, promoCodeValue },
                i
              ) => (
                <MuPrizesCard
                  key={i}
                  type={type}
                  img={image}
                  title={title}
                  tgLink={link}
                  copy={promoCodeValue}
                  file={file}
                />
              )
            )}
        </div>
      </div>
    </section>
  );
};
export default MyPrizes;
