import React from "react";
import ModalQuiz from "../template/ModalQuiz";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import styleBtn from "../../UI/btn/Button.module.scss";
import { useDispatch } from "react-redux";
import { stepTo } from "../../../store/appSlice";

const ModalQuizFail = ({ open }) => {
  const dispatch = useDispatch();

  return (
    <ModalQuiz open={open}>
      <div className={clsx(style.quiz)}>
        <div className={clsx(style.quiz__inner, style["quiz__inner--fail"])}>
          <div className={clsx(style.quiz__title, styleTitle.title)}>
            <h2
              className={clsx(
                styleTitle.title__inner,
                styleTitle["title__inner--fail"]
              )}
            >
              Увы, не повезло
            </h2>
          </div>

          <p className={clsx(style.quiz__text, style["quiz__text--fail"])}>
            Не отчаивайся! Возможно тебе повезет в следующий раз.
          </p>

          <div className={clsx(style.quiz__copy, style.copy)}>
            <button
              type="button"
              className={clsx(styleBtn.button)}
              onClick={() => {
                dispatch(stepTo({ type: "check", step: 1 }));
              }}
            >
              Загрузить чек
            </button>
          </div>
        </div>
      </div>
    </ModalQuiz>
  );
};
export default ModalQuizFail;
