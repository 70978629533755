import React, { useState } from "react";
import style from "./Header.module.scss";
import styleBtn from "../UI/btn/Button.module.scss";
import clsx from "clsx";
import Logo from "../UI/logo/Logo";
import Links from "./Links";
import { ReactComponent as BurgerIcon } from "../../images/svg/burger.svg";
import btnIconStyle from "../UI/btn/ButtonIcon.module.scss";
import Menu from "react-burger-menu/lib/menus/slide";
// import useScreenType from "react-screentype-hook";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { stepTo } from "../../store/appSlice";

const Header = () => {
  const dispatch = useDispatch();

  const {
    user: { firstName },
    isMobileOrTablet,
  } = useSelector((state) => state.app);

  // const screenType = useScreenType({
  //   mobile: 320,
  //   tablet: 769,
  // });
  // const { isMobile } = screenType;

  const [isMenu, setMenu] = useState(false);

  return (
    <header className={clsx(style.header)}>
      <div className={clsx(style.header__inner)}>
        <Logo parent={"header"} />
        {isMobileOrTablet && (
          <Menu
            isOpen={isMenu}
            onStateChange={(state) => {
              setMenu(state.isOpen);
            }}
            width={"100%"}
            noOverlay
            customBurgerIcon={false}
            className={clsx(style.header__nav, style.nav)}
          >
            <Links setMenu={setMenu} />

            {firstName && (
              <HashLink
                smooth
                to={"/private"}
                className={clsx(
                  styleBtn.button,
                  styleBtn["button--header"],
                  styleBtn["button--header--mob"]
                )}
                onClick={() => {
                  setMenu(false);
                }}
              >
                {firstName}
              </HashLink>
            )}

            {!firstName && (
              <button
                className={clsx(
                  styleBtn.button,
                  styleBtn["button--header"],
                  styleBtn["button--header--mob"]
                )}
                onClick={() => dispatch(stepTo({ type: "auth", step: 2 }))}
              >
                личный кабинет
              </button>
            )}
          </Menu>
        )}

        {!isMobileOrTablet && (
          <nav className={clsx(style.header__nav, style.nav)}>
            <Links setMenu={setMenu} />
          </nav>
        )}

        {firstName && (
          <HashLink
            smooth
            to={"/private"}
            className={clsx(styleBtn.button, styleBtn["button--header"])}
          >
            {firstName}
          </HashLink>
        )}

        {!firstName && (
          <button
            className={clsx(styleBtn.button, styleBtn["button--header"])}
            onClick={() => dispatch(stepTo({ type: "auth", step: 2 }))}
          >
            личный кабинет
          </button>
        )}

        <button
          className={clsx(
            btnIconStyle.button,
            btnIconStyle["button--xs"],
            btnIconStyle["button--header"],
            btnIconStyle["button--primary"]
          )}
          aria-label="меню"
          onClick={() => {
            setMenu(!isMenu);
          }}
        >
          <BurgerIcon />
        </button>
      </div>
    </header>
  );
};
export default Header;
