import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useFormik } from "formik";
import * as yup from "yup";
import { required, nameMin, valid, nameMax } from "../../UI/form/errText";
import { onNameInput } from "../../../service/form/mask/name";
import { mePutUserMe } from "../../../store/axs/me";
import { onAgeInput } from "../../../service/form/mask/age";
import { errorShow, clearAllStep } from "../../../store/appSlice";
import { useDispatch } from "react-redux";

const ModalPrivate1 = ({ open, user }) => {
  const jwt = window.localStorage.getItem("access");
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(required)
      .matches(/[^-]$/, valid)
      .min(2, nameMin)
      .max(50, nameMax),

    email: yup
      .string()
      .required(required)
      .matches(/@[^.]*\./, valid)
      .email(valid),

    lastName: yup
      .string()
      .required(required)
      .matches(/[^-]$/, valid)
      .min(2, nameMin)
      .max(50, nameMax),
  });

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      age: user.birthDate ? user.birthDate.split("-").reverse().join(".") : "",
      gender: user.gender,
    },

    enableReinitialize: true,

    validationSchema,
    onSubmit: (values) => {
      mePutUserMe(
        jwt,
        values.firstName,
        values.lastName,
        values.email,
        values.gender,
        values.age.split(".").reverse().join("-")
      ).then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }
        dispatch(errorShow("Ваши изменения успешно отправлены"));
        dispatch(clearAllStep());
      });
    },
  });

  return (
    <Modal open={open}>
      <form
        onSubmit={formik.handleSubmit}
        className={clsx(style.modal__form, style["modal__form--big"])}
      >
        <div className="form__block ">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Имя</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.firstName && "input-block__input--error"
                )}
                placeholder="Введите свое имя"
                name="firstName"
                onInput={onNameInput}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.firstName ? (
              <p className="input-block__error">{formik.errors.firstName}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Фамилия</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.lastName && "input-block__input--error"
                )}
                placeholder="Введите свою фамилию"
                name="lastName"
                onInput={onNameInput}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.lastName ? (
              <p className="input-block__error">{formik.errors.lastName}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block ">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Email</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.email && "input-block__input--error"
                )}
                placeholder="example@mail.ru"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.email ? (
              <p className="input-block__error">{formik.errors.email}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block radio">
          <span className="radio__title">Пол</span>
          <div className="radio__content">
            <label className="radio__label">
              <span className="radio__name">Мужской</span>
              <input
                type="radio"
                className="radio__input"
                value="man"
                name="gender"
                onChange={formik.getFieldProps("gender").onChange}
                defaultChecked={user.gender === "man"}
              />
              <span className="radio__indicator"></span>
            </label>

            <label className="radio__label">
              <span className="radio__name">Женский</span>
              <input
                type="radio"
                className="radio__input"
                value="woman"
                name="gender"
                onChange={formik.getFieldProps("gender").onChange}
                defaultChecked={user.gender === "woman"}
              />
              <span className="radio__indicator"></span>
            </label>
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Возраст</span>
              <input
                type="text"
                className={clsx(
                  "input-block__input",
                  formik.errors.age && "input-block__input--error"
                )}
                placeholder="Дата рождения"
                name="age"
                onInput={onAgeInput}
                value={formik.values.age}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.age ? (
              <p className="input-block__error">{formik.errors.age}</p>
            ) : null}
          </div>
        </div>
        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          disabled={!formik.isValid}
        >
          Продолжить
        </button>
      </form>
    </Modal>
  );
};
export default ModalPrivate1;
