import React from "react";
import style from "./Promo.module.scss";
import clsx from "clsx";
import CountDown from "./CountDown";
import imgWebp from "../../../images/promo/promo.webp";
import imgWebpMob from "../../../images/promo/promo-mob.webp";
import imgPng from "../../../images/promo/promo.png";
import imgPngMob from "../../../images/promo/promo-mob.png";
import { SafePic } from "react-safe-pic";
// import useScreenType from "react-screentype-hook";
import imgPngMaskotMob from "../../../images/promo/promo_maskot.png";
import imgWebpMaskotMob from "../../../images/promo/promo_maskot.webp";
import { useSelector } from "react-redux";

const Promo = () => {
  // const screenType = useScreenType({
  //   mobile: 320,
  //   tablet: 769,
  // });
  // const { isMobile } = screenType;
  const { isMobileOrTablet } = useSelector((state) => state.app);

  return (
    <section className={clsx(style.promo)}>
      <div className={clsx(style.promo__inner)}>
        <h1 className={clsx(style.promo__title, style["promo-title"])}>
          <span
            className={clsx(
              style["promo-title__left"],
              style["promo-title__block"],
              style["title-left"]
            )}
          >
            <span className={clsx(style["title-left__top"])}>Степ</span>
            <span className={clsx(style["title-left__down"])}>роял</span>
          </span>
          <span
            className={clsx(
              style["promo-title__right"],
              style["promo-title__block"],
              style["title-right"]
            )}
          >
            <span className={clsx(style["title-right__top"])}>Выигрывай</span>
            <span className={clsx(style["title-right__down"])}>с орехами!</span>
          </span>
        </h1>

        <CountDown />
      </div>
      <figure className={clsx(style["promo__img-wrap"])}>
        {!isMobileOrTablet && (
          <SafePic regularImage={imgPng} webpImage={imgWebp} />
        )}
        {isMobileOrTablet && (
          <SafePic regularImage={imgPngMob} webpImage={imgWebpMob} />
        )}
      </figure>

      <figure className={clsx(style.promo__maskot)}>
        <SafePic regularImage={imgPngMaskotMob} webpImage={imgWebpMaskotMob} />
      </figure>
    </section>
  );
};
export default Promo;
