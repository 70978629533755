import React, { useState } from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import {
  stepTo,
  clearAllStep,
  setUser,
  // errorClose,
} from "../../../store/appSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  passwordMin,
  passwordMax,
  required,
  valid,
} from "../../UI/form/errText";
import { onPhoneInput, onPhoneKeyDown } from "../../../service/form/mask/phone";
import { authAuthorization } from "../../../store/axs/auth";
import { meGetUserMe } from "../../../store/axs/me";
import { ReactComponent as IconEye } from "../../../images/svg/eye.svg";
import { ReactComponent as IconEyeOff } from "../../../images/svg/eye-off.svg";

const ModalAuth2 = ({ open }) => {
  const [isTypeText, setType] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    phone: yup.string().required(required).min(18, valid),
    password: yup
      .string()
      .required(required)
      .min(8, passwordMin)
      .max(14, passwordMax),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },

    validationSchema,
    onSubmit: (values) => {
      authAuthorization(values.phone, values.password)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }
          window.localStorage.clear();
          window.localStorage.setItem("access", res.data.access);

          meGetUserMe(res.data.access).then((res) => {
            if (res.status !== 200) {
              throw new Error();
            }

            dispatch(setUser(res.data));
            // dispatch(errorClose());
          });

          dispatch(clearAllStep());
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  });

  return (
    <Modal open={open}>
      <form onSubmit={formik.handleSubmit} className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Авторизация</h2>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Телефон</span>
              <input
                type="tel"
                className={clsx(
                  "input-block__input",
                  formik.errors.phone && "input-block__input--error"
                )}
                placeholder="+7 (___) __ ______"
                name="phone"
                onInput={onPhoneInput}
                onKeyDown={onPhoneKeyDown}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.phone ? (
              <p className="input-block__error">{formik.errors.phone}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Пароль</span>
              <input
                type={isTypeText ? "text" : "password"}
                className={clsx(
                  "input-block__input",
                  formik.errors.password && "input-block__input--error"
                )}
                placeholder=" "
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <button
                type="button"
                className="input-block__eye"
                onClick={() => setType(!isTypeText)}
              >
                {!isTypeText && <IconEye />}
                {isTypeText && <IconEyeOff />}
              </button>
            </label>
            {formik.errors.password ? (
              <p className="input-block__error">{formik.errors.password}</p>
            ) : null}
          </div>

          <button
            className={clsx(style.modal__forget, "form__err ")}
            type="button"
            onClick={() => {
              dispatch(stepTo({ type: "recovery", step: 1 }));
            }}
          >
            Забыли пароль?
          </button>
        </div>

        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          disabled={!formik.isValid || !formik.dirty}
        >
          войти
        </button>

        <button
          type="button"
          className={clsx(style.modal__not)}
          onClick={() => {
            dispatch(stepTo({ type: "registration", step: 1 }));
          }}
        >
          Нет аккаунта? регистрация
        </button>
      </form>
    </Modal>
  );
};
export default ModalAuth2;
