import React, { useState, useEffect } from "react";
import Promo from "../../components/home/promo/Promo";
import Prizes from "../../components/home/prizes/Prizes";
import MultyHow from "../../components/home/multy-how/MultyHow";
import MultyWin from "../../components/home/multy-win/MultyWin";
import Faq from "../../components/home/faq/Faq";
import Feedback from "../../components/home/feedback/Feedback";
import { useSelector } from "react-redux";
import ModalManager from "../../components/modal/ModalManager";

const HomePage = () => {
  const [modalOpen, setModal] = useState("");
  const { modalState } = useSelector((state) => state.app);

  useEffect(() => {
    document.title = "Степ Роял. Выигрывай с орехами!";
  }, []);

  useEffect(() => {
    const modalName = Object.keys(modalState).filter((item) => {
      return modalState[`${item}`].step !== 0;
    });

    if (modalName.length !== 0) {
      const name = modalName[0];
      const numStep = modalState[`${modalName}`].step;

      setModal(`${name}-${numStep}`);
    } else {
      setModal("");
    }
  }, [modalState]);

  return (
    <>
      <Promo />
      <Prizes />
      <MultyHow />
      <MultyWin />
      <Faq />
      <Feedback />
      <ModalManager modal={modalOpen} />
      <div id="modal-root"></div>
    </>
  );
};
export default HomePage;
