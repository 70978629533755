const slotItem = new Array(15).fill(" ");

const list1 = [0, 1, 2, 3, 4];
const list2 = [1, 2, 3, 4, 4, 3, 5, 5, 5];
const list3 = [5, 0];

const getDataImg = (num) => {
  let data;
  switch (num) {
    case 0:
      data = "dance";
      break;
    case 1:
      data = "sticker";
      break;

    case 2:
      data = "ozon500";
      break;
    case 3:
      data = "ozon1000";
      break;

    case 4:
      data = "ozon3000";
      break;

    case 5:
      data = "danceball";
      break;

    default:
      break;
  }

  return data;
};

export const getRandomItem = (items) => {
  return items[Math.floor(Math.random() * items.length)];
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const getItemSlot = () => {
  const list = slotItem.map((item, i) => {
    if (i < 5) {
      return {
        id: i,
        list: [getDataImg(i), getDataImg(i), getDataImg(i)],
      };
    } else {
      return {
        id: 5,
        list: [
          `${getDataImg(getRandomItem(list1))}`,
          `${getDataImg(getRandomItem(list2))}`,
          `${getDataImg(getRandomItem(list3))}`,
        ],
      };
    }
  });

  return shuffle(list);
};
