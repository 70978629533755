import axios from "axios";
import { resetUser } from "../appSlice";
import { store } from "..";
import { getErrText } from "./getErrText";

const axs = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
});

axs.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.localStorage.clear();
      store.dispatch(resetUser());
      return;
    }
    getErrText(error);
  }
);

export default axs;
