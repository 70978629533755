import React, { useEffect } from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { clearCookie } from "./js/setCookies";

const ModalRegistration4 = ({ open }) => {
  useEffect(() => clearCookie(), []);
  return (
    <Modal open={open}>
      <div className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>
          Вы успешно зарегистрированы
        </h2>
      </div>
    </Modal>
  );
};
export default ModalRegistration4;
