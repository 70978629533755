import React from "react";
import style from "./Faq.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import Accordeon from "./Accordeon";
const Faq = () => {
  return (
    <section id="faq" className={clsx(style.faq)}>
      <div className={clsx(style.faq__inner)}>
        <div className={clsx(style.faq__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--faq"]
            )}
          >
            faq
          </h2>
        </div>

        <div className={clsx(style.faq__wrap)}>
          <Accordeon />
        </div>
      </div>
    </section>
  );
};
export default Faq;
