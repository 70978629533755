import React, { useRef } from "react";
import ModalQuiz from "../template/ModalQuiz";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import styleBtn from "../../UI/btn/Button.module.scss";
import { useDispatch } from "react-redux";
import { stepTo } from "../../../store/appSlice";
import { ReactComponent as CopyIcon } from "../../../images/svg/copy.svg";
import { SafePic } from "react-safe-pic";
import imgPng1000 from "../../../images/quiz/imgPng1000.png";

const ModalQuizOzon1000 = ({ open, code }) => {
  const dispatch = useDispatch();

  const prizePrice = 1000;

  const copyRef = useRef();

  const handleCopy = () => {
    const input = copyRef.current;
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
    dispatch(stepTo({ type: "private", step: 3 }));
  };
  return (
    <ModalQuiz open={open}>
      <div className={clsx(style.quiz)}>
        <div className={clsx(style.quiz__inner)}>
          <div className={clsx(style.quiz__title, styleTitle.title)}>
            <h2
              className={clsx(
                styleTitle.title__inner,
                styleTitle["title__inner--quiz"]
              )}
            >
              приз
            </h2>
          </div>

          <p className={clsx(style.quiz__text)}>
            Твой приз — сертификат Озон на {prizePrice} рублей!
          </p>

          <div className={clsx(style.quiz__copy, style.copy)}>
            <button
              type="button"
              className={clsx(styleBtn.button, style.copy__link)}
              onClick={handleCopy}
            >
              {code}
            </button>

            <input
              ref={copyRef}
              readOnly
              className={clsx(style.copy__input)}
              value={code}
              type="text"
              name="copy"
              size={1}
            />

            <button
              type="button"
              className={clsx(style.copy__button)}
              onClick={handleCopy}
              title="Скопировать"
            >
              <CopyIcon />
            </button>
          </div>
        </div>

        <button
          type="button"
          className={clsx(style.quiz__rule)}
          onClick={() => dispatch(stepTo({ type: "private", step: 2 }))}
        >
          Правила использования
        </button>

        <figure
          className={clsx(style.quiz__maskot, style["quiz__maskot--ozon1000"])}
        >
          <SafePic regularImage={imgPng1000} />
        </figure>
      </div>
    </ModalQuiz>
  );
};
export default ModalQuizOzon1000;
