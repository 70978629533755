import style from "../Step.module.scss";

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const animationBtn = async (e) => {
  const list = Array.from(e.target.querySelectorAll("figure"));

  e.target.style.pointerEvents = "none";

  list[0].classList.remove(style.show);
  await sleep(100);
  list[1].classList.add(style.show);
  await sleep(100);
  list[1].classList.remove(style.show);
  await sleep(100);
  list[2].classList.add(style.show);
  await sleep(100);
  list[2].classList.remove(style.show);
  list[0].classList.add(style.show);
  await sleep(3100);
  e.target.style.pointerEvents = "auto";
};
