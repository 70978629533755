import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";

const ModalError = ({ open, title }) => {
  return (
    <Modal open={open}>
      <div className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>{title}</h2>
      </div>
    </Modal>
  );
};
export default ModalError;
