import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { stepTo } from "../../../store/appSlice";

const ModalRecovery2 = ({ open }) => {
  const dispatch = useDispatch();

  const {
    user: { phoneNumber },
  } = useSelector((state) => state.app);

  return (
    <Modal open={open}>
      <div className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Восстановление пароля</h2>

        <div className="form__block">
          <div className="form__field  input-block">
            <p className={clsx("input-block__info")}>
              На {phoneNumber ? `номер ${phoneNumber}` : "Ваш номер телефона"}{" "}
              был отправлен новый пароль.
            </p>
            <p className={clsx("input-block__info")}>
              Используйте его для входа в свой профиль.
            </p>
          </div>
        </div>

        <button
          type="button"
          className={clsx(style.modal__button, "form__button")}
          onClick={() => {
            dispatch(stepTo({ type: "auth", step: 2 }));
          }}
        >
          Авторизация
        </button>
      </div>
    </Modal>
  );
};
export default ModalRecovery2;
