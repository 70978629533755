import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import Rule from "./Rule";

const ModalCheck1 = ({ open }) => {
  return (
    <Modal open={open}>
      <form action="" className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Загрузить чек</h2>

        <Rule />

        <div className="form__block">
          <div className="form__field  input-block">
            {/* <label className="input-block__label input-file">
              <input
                name="file[]"
                accept="image/*"
                type="file"
                className="input-file__input"
                onChange={handleChange}
              />
              <span className="input-file__btn">
                <PLusIcon />
                <span className="input-file__title">
                  Выберите его на своем устройстве
                </span>
              </span>
            </label>
            <div className="input-file-list"></div> */}
            <div id="revizor" className="revizor"></div>
          </div>
        </div>

        {/* <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
        >
          Загрузить
        </button> */}
      </form>
    </Modal>
  );
};
export default ModalCheck1;
