import { sleep } from "./animationBtn";

export const slotStart = (s) => {
  if (s.destroyed) {
    return;
  }

  s.params.autoplay.delay = 10;

  s.autoplay.start();
};

export const slotMove = async (s, type) => {
  if (s.destroyed) {
    console.log("destroyed");
    return;
  }

  const slides = s.slides;

  const list = slides.filter((item) => {
    return Number(item.dataset.id) === Number(type);
  });
  let index;
  s.update();

  s.params.autoplay.delay = 10;
  await sleep(200);
  s.autoplay.start();
  await sleep(1500);
  s.autoplay.stop();

  let position = 0;

  while ([14, 13, 0].indexOf(position) >= 0) {
    s.autoplay.start();
    await sleep(500);
    s.autoplay.stop();
    position = s.slides.indexOf(list[0]);
  }

  index = Number(list[0].dataset.swiperSlideIndex);

  if (index === 0) {
    index = 14;
  } else {
    index = index - 1;
  }

  s.slideToLoop(index, 1000);

  await sleep(1000);
};
