import React from "react";
import style from "./Footer.module.scss";
import styleLink from "../UI/links/Link.module.scss";
import clsx from "clsx";
import Logo from "../UI/logo/Logo";
// import { ReactComponent as TgIcon } from "../../images/svg/telegram.svg";
import { ReactComponent as VkIcon } from "../../images/svg/vk.svg";
// import { ReactComponent as OkIcon } from "../../images/svg/odnoklassniki.svg";
import { useSelector } from "react-redux";

const Footer = () => {
  const {
    settings: { terms, rules },
  } = useSelector((state) => state.app);
  return (
    <footer className={clsx(style.footer)}>
      <div className={clsx(style.footer__inner)}>
        <Logo parent={"footer"} />
        <div className={clsx(style.footer__soc, style.soc)}>
          {/* <a
            className={clsx(style.soc__item)}
            href={process.env.REACT_APP_LINK_TG}
            aria-label="telegram"
            target="_blank" rel="noreferrer"
          >
            <TgIcon />
          </a> */}
          <a
            className={clsx(style.soc__item)}
            href={process.env.REACT_APP_LINK_VK}
            aria-label="vk"
            target="_blank"
            rel="noreferrer"
          >
            <VkIcon />
          </a>
          {/* <a
            className={clsx(style.soc__item)}
            href={process.env.REACT_APP_LINK_OK}
            aria-label="ok"
            target="_blank" rel="noreferrer"
          >
            <OkIcon />
          </a> */}
        </div>

        <div className={clsx(style.footer__doc, style.doc)}>
          <a
            className={clsx(styleLink.link, style.doc__link)}
            href={terms}
            target="_blank"
            rel="noreferrer"
          >
            Пользовательское соглашение
          </a>
          <a
            className={clsx(styleLink.link, style.doc__link)}
            href={rules}
            target="_blank"
            rel="noreferrer"
          >
            правила акции
          </a>
        </div>

        <div className={clsx(style.footer__art)}>
          <p className={clsx(style.art__text)}>
            <span>Организатор акции</span> <span>ООО «Арт-Композишн»</span>
          </p>
        </div>

        <div className={clsx(style.footer__promo, style.promo)}>
          <p className={clsx(style.promo__text)}>2023 © PROMO STEP</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
