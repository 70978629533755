import React from "react";
import style from "./MultyHow.module.scss";
import clsx from "clsx";
import How from "../how/How";
import Step from "../step/Step";

const MultyHow = () => {
  return (
    <div className={clsx(style.multy, style["multy--how"])}>
      <How />
      <Step />
    </div>
  );
};
export default MultyHow;
