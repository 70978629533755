import React, { useState, useEffect } from "react";
import MyPrizes from "../../components/private/my-prizes/MyPrizes";
import Uploaded from "../../components/private/uploaded/Uploaded";
import { useSelector } from "react-redux";
import ModalManager from "../../components/modal/ModalManager";
import Step from "../../components/private/step/Step";
import { Navigate } from "react-router-dom";

const Private = () => {
  const [modalOpen, setModal] = useState("");
  const jwt = window.localStorage.getItem("access");
  const {
    modalState,
    // user: { firstName },
  } = useSelector((state) => state.app);

  useEffect(() => {
    document.title = "Степ Роял. Выигрывай с орехами! || Профиль";
  }, []);

  useEffect(() => {
    const modalName = Object.keys(modalState).filter((item) => {
      return modalState[`${item}`].step !== 0;
    });

    if (modalName.length !== 0) {
      const name = modalName[0];
      const numStep = modalState[`${modalName}`].step;

      setModal(`${name}-${numStep}`);
    } else {
      setModal("");
    }
  }, [modalState]);

  if (!jwt) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Step />
      <MyPrizes />
      <Uploaded />
      <ModalManager modal={modalOpen} />
      <div id="modal-root"></div>
    </>
  );
};
export default Private;
