import React from "react";
import Modal from "../template/Modal";
import style from "../template/Modal.module.scss";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { stepTo } from "../../../store/appSlice";
import { setPhoneNumber, setVerificationToken } from "../../../store/appSlice";
import { ReactComponent as CheckIcon } from "../../../images/svg/check.svg";
import { onPhoneInput, onPhoneKeyDown } from "../../../service/form/mask/phone";
import { useFormik } from "formik";
import * as yup from "yup";
import { required, requiredCeck, valid } from "../../UI/form/errText";
import { regSendVerificationCode } from "../../../store/axs/registration";

const ModalRegistration1 = ({ open }) => {
  const dispatch = useDispatch();

  const {
    settings: { terms, rules },
  } = useSelector((state) => state.app);

  const validationSchema = yup.object().shape({
    phone: yup.string().required(required).min(18, valid),
    rule: yup.boolean().oneOf([true], requiredCeck),
    agree: yup.boolean().oneOf([true], requiredCeck),
  });


  const formik = useFormik({
    initialValues: {
      phone: "",
      rule: true,
      agree: true,
    },

    validationSchema,
    onSubmit: (values) => {
      regSendVerificationCode(values.phone)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }

          dispatch(setPhoneNumber(values.phone));
          dispatch(setVerificationToken(res.data.verificationToken));
          dispatch(stepTo({ type: "registration", step: 2 }));
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  });

  return (
    <Modal open={open}>
      <form onSubmit={formik.handleSubmit} className={clsx(style.modal__form)}>
        <h2 className={clsx(style.modal__title)}>Регистрация</h2>

        <div className="form__block">
          <div className="form__field  input-block">
            <label className="input-block__label">
              <span className="input-block__name">Телефон</span>
              <input
                type="tel"
                className={clsx(
                  "input-block__input",
                  formik.errors.phone && "input-block__input--error"
                )}
                placeholder="+7 (___) __ ______"
                name="phone"
                onInput={onPhoneInput}
                onKeyDown={onPhoneKeyDown}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.errors.phone ? (
              <p className="input-block__error">{formik.errors.phone}</p>
            ) : null}
          </div>
        </div>

        <div className="form__block form__block--checkbox">
          <div className="form__field">
            <label className="form__label checkbox">
              <input
                className="checkbox__input"
                type="checkbox"
                name="rule"
                value={formik.values.rule}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultChecked={true}
              />
              <span className="checkbox__indicator">
                <CheckIcon />
              </span>
              <span className="checkbox__label">
                <span>Согласен(на)</span>
                &nbsp;
                <a
                  className="link-doc"
                  href={rules}
                  target="_blank"
                  rel="noreferrer"
                >
                  с правилами акции
                </a>
              </span>
            </label>
            {formik.errors.rule ? (
              <p className="input-block__error">{formik.errors.rule}</p>
            ) : null}
          </div>

          <div className="form__field">
            <label className="form__label checkbox">
              <input
                className="checkbox__input"
                type="checkbox"
                name="agree"
                defaultChecked={true}
                value={formik.values.agree}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="checkbox__indicator">
                <CheckIcon />
              </span>
              <span className="checkbox__label">
                <span>Согласен(на) на обработку</span>
                &nbsp;
                <a
                  className="link-doc"
                  href={terms}
                  target="_blank"
                  rel="noreferrer"
                >
                  персональных данных
                </a>
              </span>
            </label>
            {formik.errors.agree ? (
              <p className="input-block__error">{formik.errors.agree}</p>
            ) : null}
          </div>
        </div>

        <button
          type="submit"
          className={clsx(style.modal__button, "form__button")}
          disabled={!formik.isValid || !formik.dirty}
        >
          регистрация
        </button>

        <button
          type="button"
          className={clsx(style.modal__not)}
          onClick={() => {
            dispatch(stepTo({ type: "auth", step: 2 }));
          }}
        >
          Уже есть аккаунт? Войти
        </button>
      </form>
    </Modal>
  );
};
export default ModalRegistration1;
