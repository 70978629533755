import React from "react";
import style from "./Prizes.module.scss";
import clsx from "clsx";
import { SafePic } from "react-safe-pic";

const Slide = ({ item, i }) => {
  return (
    <div className={clsx(style.slide, style[`slide--${i + 1}`])}>
      <div className={clsx(style.slide__marquee, style.marquee)}>
        <ul className={clsx(style.marquee__list)}>
          <li className={clsx(style.marquee__item)}>{item.marquee}</li>
          <li className={clsx(style.marquee__item)}>{item.marquee}</li>
          <li className={clsx(style.marquee__item)}>{item.marquee}</li>
        </ul>
      </div>

      <figure className={clsx(style.slide__img)}>
        <SafePic regularImage={item.imgPng} webpImage={item.imgWebp} />
      </figure>

      <h3 className={clsx(style.slide__title)}>
        <span>{item.title[0]}</span>
        <span>{item.title[1]}</span>
      </h3>
    </div>
  );
};
export default Slide;
