import { createSlice } from "@reduxjs/toolkit";
import { replica_start } from "../service/vairables";

const body = document.querySelector("body");

const listModal = [
  "auth",
  "registration",
  "recovery",
  "check",
  "private",
  "quiz",
];
const initModal = (state) => {
  // eslint-disable-next-line no-new-object
  const obj = new Object();
  listModal.forEach((item) => {
    obj[`${item}`] = { step: 0 };
  });

  return obj;
};

const initialmyPrizes = { count: 0, next: null, previous: null, results: [] };

const appSlice = createSlice({
  name: "app",
  initialState: {
    modalState: {
      auth: { step: 0 },
      registration: { step: 0 },
      recovery: { step: 0 },
      check: { step: 0 },
      private: { step: 0 },
      quiz: { step: 0 },
    },
    error: {
      isError: false,
      detail: null,
    },
    user: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      gender: null,
      birthDate: null,
      verificationToken: null,
    },
    settings: {
      rules: null,
      terms: null,
      startDate: null,
      endDate: null,
    },
    actualTime: { days: null, hours: null, minutes: null },
    stateAction: 0,
    faq: null,
    receipt: null,
    win: null,
    replica: replica_start,
    myPrizes: initialmyPrizes,
    isPossible: { drawAvailable: false, attemptsLeft: null },
    isMobileOrTablet: false,
    topWinningIphone: null,
    topWinningIpad: null,
  },
  reducers: {
    stepTo(state, action) {
      if (body) {
        body.classList.add("isModal");
      }

      state.modalState = initModal();
      state.modalState[`${action.payload.type}`].step = action.payload.step;
    },

    clearAllStep(state) {
      if (body) {
        body.classList.remove("isModal");
      }

      state.modalState = initModal();
    },

    clearError(state) {
      if (body) {
        body.classList.remove("isModal");
      }
      state.errorServer.status = null;
      state.errorServer.detail = null;
    },

    setPhoneNumber(state, action) {
      state.user.phoneNumber = action.payload;
    },

    setVerificationToken(state, action) {
      state.user.verificationToken = action.payload;
    },

    errorShow(state, action) {
      state.error.isError = true;
      state.error.detail = action.payload;
    },

    errorClose(state) {
      state.error.isError = false;
      state.error.detail = null;
    },

    setSettings(state, action) {
      state.settings.rules = action.payload.rules;
      state.settings.terms = action.payload.terms;
    },

    setUser(state, action) {
      state.user.id = action.payload.id;
      state.user.firstName = action.payload.firstName;
      state.user.lastName = action.payload.lastName;
      state.user.email = action.payload.email;
      state.user.phoneNumber = action.payload.phoneNumber;
      state.user.gender = action.payload.gender;
      state.user.birthDate = action.payload.birthDate;
    },

    resetUser(state) {
      state.user.id = null;
      state.user.firstName = null;
      state.user.lastName = null;
      state.user.email = null;
      state.user.phoneNumber = null;
      state.user.gender = null;
      state.user.birthDate = null;
      state.receipt = null;
      state.myPrizes = initialmyPrizes;
      state.isPossible = { drawAvailable: false, attemptsLeft: null };
    },

    setFaq(state, action) {
      state.faq = action.payload;
    },

    setReceipt(state, action) {
      state.receipt = action.payload;
    },
    setWin(state, action) {
      state.win = action.payload;
    },
    setReplica(state, action) {
      state.replica = action.payload;
    },

    setMyPrizes(state, action) {
      state.myPrizes = action.payload;
    },
    setStateAction(state, action) {
      state.stateAction = action.payload;
    },
    setIsPossible(state, action) {
      state.isPossible.drawAvailable = action.payload.drawAvailable;
      state.isPossible.attemptsLeft = action.payload.attemptsLeft;
    },
    setActualTime(state, action) {
      const { days, hours, minutes } = action.payload;
      state.actualTime = {
        days,
        hours,
        minutes,
      };
    },
    setIsMobileOrTablet(state, action) {
      state.isMobileOrTablet = action.payload;
    },
    setTopWinningIphone(state, action) {
      state.topWinningIphone = action.payload;
    },
    setTopWinningIpad(state, action) {
      state.topWinningIpad = action.payload;
    },
  },
});

export const {
  stepTo,
  clearAllStep,
  setPhoneNumber,
  errorShow,
  setVerificationToken,
  errorClose,
  setSettings,
  setUser,
  resetUser,
  setFaq,
  setReceipt,
  setWin,
  setReplica,
  setMyPrizes,
  setStateAction,
  setIsPossible,
  setActualTime,
  setIsMobileOrTablet,
  setTopWinningIphone,
  setTopWinningIpad,
} = appSlice.actions;
export default appSlice.reducer;
