import style from "../template/Modal.module.scss";
import clsx from "clsx";

const Rule = () => {
  return (
    <div className={clsx(style.rule)}>
      <p className={clsx(style.rule__title)}>Правила загрузки чека</p>

      <ul className={clsx(style.rule__list)}>
        <li className={clsx(style.rule__item)}>
          <span className={clsx(style.rule__num)}>1.</span>
          <span>
            К участию в акции принимаются чеки, покупки по которым были
            осуществлены в период с 30.10.2023 г. 00:00 по 31.12.2023 г., 23:59
            по МСК
          </span>
        </li>
        <li className={clsx(style.rule__item)}>
          <span className={clsx(style.rule__num)}>2.</span>
          <span>
            {" "}
            На чеке обязательно должны присутствовать: номер чека, дата и время
            покупки, итоговая сумма, ФН, ФД. ФПД, QR-код.
          </span>
        </li>
        <li className={clsx(style.rule__item)}>
          <span className={clsx(style.rule__num)}>3.</span>
          <span>
            В чеке обязательно должен присутствовать продукт, участвующий в
            акции.
          </span>
        </li>
        <li className={clsx(style.rule__item)}>
          <span className={clsx(style.rule__num)}>4.</span>
          <span>
            В одну заявку можно загрузить только 1 чек. Нельзя загружать в форму
            несколько чеков за раз.
          </span>
        </li>
        <li className={clsx(style.rule__item)}>
          <span className={clsx(style.rule__num)}>5.</span>
          <span>Файлы должны быть в формате JPEG или PNG.</span>
        </li>
        <li className={clsx(style.rule__item)}>
          <span className={clsx(style.rule__num)}>6.</span>
          <span>Размер загружаемого файла не должен превышать 10 МБ.</span>
        </li>
      </ul>
    </div>
  );
};
export default Rule;
